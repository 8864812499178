import '../css/App.css';
import {IImage, IPicture} from './MainInterface';
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@material-ui/core/styles";
import {IsMobile} from "./MainLibrary";


const useStyles = makeStyles((theme) => ({
  canvas: {
    textAlign: "center",
    borderStyle: "solid",
    borderColor: "red",
    borderWidth: "2px",   
    width: "70%",
    height: "70%",
    marginLeft:"15%",
  },
  card_canvas: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    borderStyle: "solid",
    borderColor: "green",
    borderWidth: "2px",  
    margin: "10px",
  },
  carousel_canvas:{
    width: "100%",
    height: "100%",
    
    //textAlign: "center",
    borderStyle: "solid",
    borderColor: "blue",
    borderWidth: "2px",  
    padding: "10px",
  }
}))

/**
 * Displays a carousel image selection
 * @param images  - array of picture ids
 * @param photo - holds function handle for image interaction
 * @returns
 */
function ShowImages(_img:IPicture) {
    const classes = useStyles();
    let cards: JSX.Element[] = [];
    //console.log("ShowCards:", _img);
    _img.images.forEach(function (image: IImage, i: number) {
      //let base64 = "data:image/jpeg;base64," + image.original;
      cards.push(
        <div
          id={"image_" + i}
          key={i}
          className={classes.card_canvas}
        >
          <div className={classes.canvas}>
            {/* <img src={image.original} height={IsMobile() ? "170px" : "350px"} alt={"image" + i} /> */}
            <img src={image.original} height="600px" alt={"image" + i} />
          </div>
        </div>
      );
    });
    return cards;
  }

  export function PictureViewer(_picture: IPicture): JSX.Element {
    const classes = useStyles();
    //console.log("_picture:", _picture);
    return (
      <div id="picture" className={classes.carousel_canvas}>
        <Carousel
          autoPlay={false}
          animation={"slide"}
          navButtonsAlwaysVisible={true}
          
        >
          {ShowImages(_picture)}
        </Carousel>
      </div>)
}

export default PictureViewer;
