

export function GalleryFood(){

  return {
      "images": [
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_023.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_023.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_024.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_024.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_025.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_025.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_026.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_026.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_032.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_033.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_033.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_034.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_034.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_035.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_035.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_037.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_037.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_038.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_038.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_039.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_039.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_040.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_040.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_041.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_041.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_042.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_042.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_043.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_043.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_044.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_044.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_045.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_045.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_046.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_046.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_047.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_047.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_048.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_048.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_049.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_049.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_050.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_050.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/galleryfood/IMG_051.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/galleryfood/SML_051.jpg"
        }
     ]
    }
}