

export function GallerySHIP(){
  return {
    "images": [
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_001.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_002.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_003.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_004.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_005.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_006.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_007.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_008.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_009.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_010.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_011.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_012.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_013.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_014.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_015.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GallerySHIP/IMG_016.jpg"}
    ]
  }
}
