

export function Gallery2019(){

  return {
  "images": [
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_014.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_023.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_023.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_024.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_024.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_025.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_025.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_026.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_026.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_032.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_033.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_033.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_034.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_034.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_035.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_035.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_036.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_036.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_037.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_037.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_038.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_038.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_039.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_039.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_040.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_040.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_041.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_041.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_042.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_042.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_043.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_043.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_044.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_044.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_045.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_045.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_046.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_046.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_047.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_047.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_048.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_048.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_049.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_049.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_050.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_050.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_051.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_051.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_052.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_052.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_053.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_053.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_054.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_054.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_055.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_055.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_056.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_056.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_057.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_057.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_058.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_058.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_059.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_059.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_060.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_060.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_061.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_061.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_062.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_062.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_063.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_063.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_064.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_064.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_065.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_065.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_066.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_066.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_067.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_067.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_068.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_068.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_069.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_069.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_070.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_070.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_071.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_071.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_072.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_072.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_073.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_073.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_074.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_074.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_075.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_075.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_076.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_076.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_077.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_077.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_078.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_078.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_079.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_079.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_080.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_080.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_081.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_081.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_082.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_082.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_083.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_083.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_084.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_084.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_085.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_085.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_086.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_086.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_087.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_087.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_088.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_088.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_089.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_089.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_090.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_090.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_091.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_091.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_092.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_092.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_093.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_093.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_094.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_094.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_095.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_095.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_096.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_096.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_097.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_097.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_098.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_098.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_099.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_099.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_100.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_100.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2019/IMG_101.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2019/SML_101.jpg"
        }
      ]
    }
}