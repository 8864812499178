/**
 * ENUMS for the thunk response states
 * idle
 * loading
 * loaded
 * error
 */
export enum ELoadingStatus {
  "idle",
  "loading",
  "loaded",
  "error",
}
