import React, { useEffect } from "react";
import { ELoadingStatus } from "../redux/Enums";
import { useAppDispatch } from "../Framework/hooks";
import { useSelector } from "react-redux";
//import Snackbar from '@mui/material/Snackbar';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DisableView from "./DisableView";

/* ICONS */
import MenuIcon from "@material-ui/icons/Menu";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import DrinkIcon from "@material-ui/icons/LocalBar";
import BarIcon from "@mui/icons-material/Liquor";
import MerchIcon from "@material-ui/icons/Storefront";
import FoodIcon from "@material-ui/icons/Restaurant";
import MusicIcon from "@material-ui/icons/Speaker";
import KaraokeIcon from "@mui/icons-material/InterpreterMode";
import GalleryIcon from "@mui/icons-material/ImageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactIcon from "@mui/icons-material/Email";
import RsvpIcon from "@mui/icons-material/Rsvp";
import FaqIcon from "@mui/icons-material/ContactSupport";
import PropsIcon from "@mui/icons-material/Construction";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from '@mui/icons-material/Groups';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
//import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VideoIcon from "@mui/icons-material/Videocam";
import PictureIcon from "@mui/icons-material/Photo";

import "../css/App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FPAppbar, { IFPAppbarProps } from "../Framework/FPAppBar/FPAppbar";
import clsx from "clsx";

import {
  //MuiThemeProvider,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";

import logo  from '../images/jamhawbannerpure.gif';
import drinkaware from '../images/drinkaware.png';
import contact_mail from '../images/mail.png';
import contact_face from '../images/fb.png';
import contact_what from '../images/whatsapp.png';
// import dancer1 from '../images/dancer.gif';
// import dancer2 from '../images/dance2.gif';
// import dancer3 from '../images/dance3.gif';
// import dancer4 from '../images/airgtr.gif';
// import dancer5 from '../images/carlton.gif';

import {
  Typography,
} from "@material-ui/core";

import "../css/Panel.css";

import {AboutUs} from "./AboutUs";
import {Drinks} from "./Drinks";
import {Music} from "./Music";
import {Faq} from "./Faq";
import {Picture} from "./Picture";
import {Video} from "./Video";
import {Bar} from "./Bar";
import {Food} from "./Food";
import {Karaoke} from "./Karaoke";
import {Props} from "./Props";
import { Merchandise } from "./Merchandise";
import { RSVP } from "./RSVP";
import {Login} from "./Login";
import {GDPR} from "./GDPR";
import {PromptGDPR} from "./PromptGDPR";
import {AttendeeList} from "./AttendeeList"

import { 
  UpdatePasswordFail,
  UpdateEmailFail,
  selectAttendeesResponseStatus,
  selectAttendeesStatus,
  selectMerchandiseResponseStatus,
  selectMerchandiseStatus,
  ResetLoadingStatus,
  validateThunk,
  updateAttendeeCountThunk,
  getAttendeesThunk,
  getMerchOrderThunk,
  UpdateLoggedIn,
  UpdateCredentials,
  selectCredentials,
  UpdateShowMenu,
  UpdateShowSidebar,   
  selectValidate,
  selectValidateStatus,
  selectLoggedIn,
} from "../redux/JamHawSlice";

import {
  ICredentials, 
  IGDPR,
  IMerchProps,
  IPromptGDPRProps,
  View,
} from '../redux/JamHawInterface';
import { Bool } from "reselect/es/types";


//var HUB = process.env.REACT_APP_HUB;
var gGDPR = localStorage.getItem("allow_gdpr");
var VERSION:any = process.env.REACT_APP_VERSION;
// var URL:any     = process.env.REACT_APP_BASE_URL
// var HUB_URL:any = process.env.REACT_APP_BASE_HUB;
var validate_args:ICredentials;
var rsvp_args:any;

export enum Color {
  "initial",
  "hilight",
  "special",
  "disable",
}

export interface IDisable{
  fnBack : (event: any) => void;
}

export interface IView{
  view: View
}

export interface IMenuItem {
  title : string;
  key: string;
  show: boolean;
  color: Color;
  icon: JSX.Element;
  subitems: IMenuItem[];
}

export const menu_list:IMenuItem[] = [
  {title: "Home", key: "INITIAL", show: true, color: Color.initial, icon: (<HomeIcon fontSize="medium"/>),  subitems: []},
  {title: "About Us", key: "ABOUT", show: true, color: Color.initial, icon: (<InfoIcon fontSize="medium"/>),  subitems: []},
  {title: "The Bar", key: "BAR", show: true, color: Color.initial, icon:  (<BarIcon fontSize="medium"/>),  subitems: []},
  {title: "Food", key: "FOOD", show: true, color: Color.initial, icon: (<FoodIcon fontSize="medium"/>),  subitems: []},
  {title: "Drinks", key: "DRINK", show: true, color: Color.initial, icon: (<DrinkIcon fontSize="medium"/>),  subitems: []},
  {title: "Music", key: "MUSIC", show: true, color: Color.initial, icon: (<MusicIcon fontSize="medium"/>),  subitems: []},
  {title: "Karaoke", key: "KARAOKE", show: true, color: Color.initial, icon: (<KaraokeIcon fontSize="medium"/>),  subitems: []},
  {title: "Gallery", key: "GALLERY", show: true, color: Color.initial, icon: (<GalleryIcon fontSize="medium"/>),  
  subitems: [
    {title: "Pictures", key: "PICTURE", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},  
    {title: "Videos", key: "VIDEO", show: true, color: Color.initial, icon: (<VideoIcon fontSize="medium"/>),  subitems: []},  
  ]},
  {title: "Props", key: "PROPS", show: true, color: Color.initial, icon: (<PropsIcon fontSize="medium"/>),  subitems: [
    {title: "Mast", key: "MAST", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},  
    {title: "Cannons", key: "CANNON", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},      
    {title: "Rigging", key: "RIGGING", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},      
    {title: "Crow's Nest", key: "CROW", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},      
    {title: "Ship's Wheel", key: "WHEEL", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},      
    {title: "Captain's Corner", key: "CORNER", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},      
    {title: "Ship", key: "SHIP", show: true, color: Color.initial, icon: (<PictureIcon fontSize="medium"/>),  subitems: []},      
  ]},
  {title: "FAQ", key: "FAQ", show: true, color: Color.initial, icon: (<FaqIcon fontSize="medium"/>),  subitems: []},
  {title: "Merchandise", key: "MERCH", show: true, color: Color.initial, icon: (<MerchIcon fontSize="medium"/>),  subitems: []},
  {title: "Contact us", key: "CONTACT", show: true, color: Color.initial, icon: (<ContactIcon fontSize="medium"/>),  subitems: []},
  {title: "Attendee List", key: "ATTENDEES", show: true, color: Color.initial, icon: (<GroupsIcon fontSize="medium"/>),  subitems: []},
  {title: "Register", key: "REGISTER", show: true, color: Color.initial, icon: (<AppRegistrationIcon fontSize="medium"/>),  subitems: []},
  {title: "RSVP", key: "RSVP", show: true, color: Color.initial, icon: (<RsvpIcon fontSize="medium"/>),  subitems: []},
  {title: "Log Out", key: "LOGOUT", show: false, color: Color.initial, icon: (<LogoutIcon fontSize="medium"/>),  subitems: []},
]

const topBarProps: IFPAppbarProps = {
  isBottomBar: false,
  isHideOnScroll: false,
  //title: "JamHaw",

};

//Theme colours
const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        MuiAccordion:
        {
          root:
          {
            Color: "green",
          }
        }
      },
    },
    MuiList: {
      root: {
        width: "100%",
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          color: "yellow",
          borderRadius: "6px",
          "& .MuiListItemIcon-root": {
            color: "white"
          }
        },        
        //paddingBottom: "0px",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",        
        borderBottomColor: "#a0a0a0",

      },
    },    
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
      },
 
    },
    MuiAccordionSummary:
    {
      root: {
        "&:hover": {
          color: "yellow",
        },          
        width: "100%",
        padding: "0px",
      },
    },
    MuiAccordionDetails: {
      root: {
        "&:hover": {
          color: "yellow",
        },             
        marginLeft: "10px",
      },
    },
  },  
  palette: {
    primary: { light: "#416000", main: "#416000", dark: "#416000" },
    secondary: { light: "#6385BB", main: "#6385BB", dark: "#6385BB" },
  },
  
});

const useStyles = makeStyles((theme) => ({
  main_canvas: {
    height: "100%",
    
    width: "100%",
    userSelect: "none",
    //Why does this stop the canvas scroll?
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "white",
    // textAlign: "center",
  },
  main_logo: {
    position: "relative",
    right: "calc(50% - 135px)",
  },
  item: {
    display: "inline-flex",
    width: "100%",
    // borderBottomStyle: "solid",
    // borderBottomWidth: "1px",
    // borderBottomColor: "silver",    
  },
  panel: {
    zIndex: 99999,
    height: "calc(100% - 62px)",
    scrollbarWidth: "none",
    backgroundColor:"#416000",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "red",  
    overflow: "auto",  
  },

  initial: {
    backgroundColor: "#416000",
    display: "inline-flex",
    width: "100%",
    // borderBottomStyle: "solid",
    // borderBottomWidth: "1px",
    // borderBottomColor: "grey", 
    color: "#ffffff",       
  },
  
  hilight: {
    backgroundColor: "#ffffff",
    display: "inline-flex",
    width: "100%",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "silver",
  },
  
  special: {
    backgroundColor: "#ffffff",
    display: "inline-flex",
    width: "100%",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "silver",
  },

  disable: {
    backgroundColor: "#ffffff",
    display: "inline-flex",
    width: "100%",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "silver",
  },
  view_canvas:{
    textAlign:"center",
    width: "100%",
  },
  sidebar_right:
  { 
    width: "40px!important",
    position: "relative",
    left: "5px",  
    top: "10px",
  },
  contact: {
  },
  dancer_zone:{
    position: "relative",
    display: "inline-flex",
    top: "500px",
    left: "20px",
  },
  dancer1: {
    height: "150px",
  },
  dancer2: {
    height: "150px",
  },
  dancer3: {
    position: "relative",
    top: "-40px",
    height: "150px",
  },
  dancer4: {
    height: "170px",
    position: "relative",
    top: "40px",

  },
  dancer5: {
    height: "150px",
  },
  group_name: {
    textAlign: "center",
  }
}));


export function MainContainer(props: any): JSX.Element {
  const dispatch  = useAppDispatch();
  const classes = useStyles();
  const credentials = useSelector(selectCredentials);
  const validate = useSelector(selectValidate);
  const validateStatus = useSelector(selectValidateStatus);
  const attendeesStatus = useSelector(selectAttendeesStatus);
  const attendeesResponseStatus = useSelector(selectAttendeesResponseStatus);
  const merchandiseStatus = useSelector(selectMerchandiseStatus);
  const merchandiseResponseStatus = useSelector(selectMerchandiseResponseStatus);
  const loggedIn = useSelector(selectLoggedIn);
  /* HOOKS */
  const [open, setOpen] = React.useState(false);

  let allow_gdpr = false;
  if(gGDPR !== null)
  {
    if(gGDPR === "allow")
    {
      allow_gdpr = true;
    }
  }
  
  /**HOOKS */
  const [panel, setPanel] = React.useState(false);
  const [view, setView] = React.useState(View.INITIAL);
  const [titles, setMenuTitles] = React.useState(menu_list);
  const [isGDPR, setIsGDPR] = React.useState(allow_gdpr);
  const [showGDPRSettings, setShowGDPRSettings] = React.useState(false);
  const [mobile, SetMobile] = React.useState(false);

  const PanelOpen = (event:any) => {
    panel ? setPanel(false) : setPanel(true);
  }

  const MenuSelect = (event:any) => {
    
    let vw = Number(View[event.currentTarget.id]);
    //toast.info("MENUSELECTED:"+vw);
    
    if((vw === View.RSVP ||vw === View.MERCH) && !loggedIn)
    {
      toast.info("You must be registered or logged in before you can RSVP");
      return;
    }    

    setPanel(false);
    setView(Number(View[event.currentTarget.id]));
    /*
    if(Number(View[event.currentTarget.id]) === View.LOGOUT)
    {
      localStorage.setItem("username", "");
      localStorage.setItem("password", "");
      window.location.reload();
      dispatch(UpdateLoggedIn(false));    
      RefreshMenu(false);  
    }
    */
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleSubmit = (username: string, password: string, groupname: string) => {
    //console.log("handleSubmit:", username, password);
    // internal_user = username;
    // internal_password = password;
    validate_args = {userName: username, passWord: password, groupName: groupname};
    //const args = {user: username, password: password, client_id: gHubConnection.connectionId, groupname: groupname};
    //alert("Send the thunk");
    dispatch(validateThunk(validate_args));

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleRSVPSubmit = (total: number) => {
    //console.log("handleRSVPSubmit:", total);

    rsvp_args = { email: credentials.userName, group: credentials.groupName, count: total};
    dispatch(updateAttendeeCountThunk(rsvp_args));
    setView(View.INITIAL);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleMerchSubmit = () => {

    setView(View.INITIAL);
    toast.info("Your request is being processed")
  }
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleClose = () => {

    setView(View.INITIAL);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const exitApplication = () => {
    setView(View.INITIAL);
  };

  const RefreshMenu = (logged:boolean) => {
    menu_list.forEach((item:IMenuItem, i:number) => {
      if(item.key === View[View.LOGOUT] && logged)
      {
        item.show = true;
      }
      if(item.key === View[View.REGISTER] && logged)
      {
        item.show = false;
      }
    })
    //console.log("USER menu_list:", menu_list);
  }

  const AllowGDPR = () =>{
    try{
      localStorage.setItem("allow_gdpr","allow");
    }catch(e:any){
      toast.error("Unable to set local storage on this OS");
    }

    setIsGDPR(true);
  }  

  const ShowSettings = () =>{
    console.log("ShowSettings");
    setShowGDPRSettings(true);
  }  

  const gdprShow = (evt) =>{
    setShowGDPRSettings(false);
  }  

  if(validateStatus === ELoadingStatus.loaded)
  {
    //alert("Validate Loaded Thunk");
    dispatch(ResetLoadingStatus());
    //console.log("validate: ", validate);

    if(validate.response === "OK" || validate.response === "PRESENT")
    {

      localStorage.setItem("username", validate_args.userName);
      localStorage.setItem("password", validate_args.passWord  );
      localStorage.setItem("groupname", validate_args.groupName);      
      
      setTimeout(function(){
        dispatch(UpdatePasswordFail(false));
        dispatch(UpdateShowMenu(true));
        dispatch(UpdateShowSidebar(true));           
        dispatch(UpdateLoggedIn(true));

        let args:ICredentials =  {userName : validate_args.userName, passWord: validate_args.passWord, groupName: validate_args.groupName};
        dispatch(UpdateCredentials(args));     
        RefreshMenu(true);
      }, 500);

      setView(View.INITIAL);
    }
    else
    {
      //console.log("VALIDATE:", validate.response);
      setTimeout(function(){
        if(validate.response === "BAD_EMAIL")
        {
            dispatch(UpdateEmailFail(true));
        }
        else  
        {
            dispatch(UpdateEmailFail(false));
        }

        if(validate.response === "PSWDFAIL")
        {
            dispatch(UpdatePasswordFail(true));
        }
        else
        {
            dispatch(UpdatePasswordFail(false));
        }
      }, 500);
    }
  }

  if(gGDPR === null)
    gGDPR = "";

  if(attendeesStatus === ELoadingStatus.loaded)
  {
    setTimeout(function(){
      dispatch(ResetLoadingStatus());
    }, 500);
  }

  if(attendeesResponseStatus === ELoadingStatus.loaded)
  {
    setTimeout(function(){
      dispatch(ResetLoadingStatus());
      dispatch(getAttendeesThunk());
      dispatch(getMerchOrderThunk());
      dispatch(UpdateShowMenu(true));
      dispatch(UpdateShowSidebar(true));           
      dispatch(UpdateLoggedIn(true));
    }, 500);
  }
 
  if(merchandiseStatus === ELoadingStatus.loaded)
  {
    setTimeout(function(){
      dispatch(ResetLoadingStatus());
      //console.log("Merchandise: ", merchandise);
    }, 500);
  }

  if(merchandiseResponseStatus  === ELoadingStatus.loaded)
  {
    setTimeout(function(){
      dispatch(ResetLoadingStatus());
      //console.log("merchandiseResponse: ", merchandiseResponse);
      dispatch(getMerchOrderThunk());
      dispatch(UpdateShowMenu(true));
      dispatch(UpdateShowSidebar(true));           
      dispatch(UpdateLoggedIn(true));
      //dispatch(UpdateView(ViewTypes.HOME_VIEW));
    }, 500);
  }

  let cbFunc:IDisable = {
    fnBack: (event: any) =>  {
      setPanel(false);
    }
  }

  let cbFuncGDPR:IDisable = {
    fnBack: (event: any) =>  {
      //do nothing
    }
  }  

  topBarProps.onPanelClick = PanelOpen;
  if(!panel)
  {
    topBarProps.panelIconElement = <MenuIcon fontSize="large"/>;
  }
  else{
    topBarProps.panelIconElement = <ClearIcon fontSize="large"/>;
  }

  useEffect(() => {
    window.addEventListener('resize', function() {
      let elem = document.getElementById("root");
      let rect = elem!.getBoundingClientRect();          
      if(rect.width < 645)
      {
          SetMobile(true);
      }  
      else
      {
          SetMobile(false);
      }
  }, true);

    let a_name = localStorage.getItem("username");
    let a_pass = localStorage.getItem("password");
    let a_group= localStorage.getItem("groupname");
    
    if((a_name === null || a_name === "" || a_name === undefined) || 
      (a_pass === null || a_pass === "" || a_pass === undefined)||
      (a_group === null || a_group === "" || a_group === undefined)
    )
    {
      console.log("Newbie");
    }
    else
    {
      setTimeout(function(){
        if(a_name===null){a_name="";}
        if(a_pass===null){a_pass="";}
        if(a_group===null){a_group="";}
        let args:ICredentials =  {userName : a_name, passWord: a_pass, groupName: a_group};
        dispatch(UpdateCredentials(args));
        dispatch(UpdateLoggedIn(true));
        //console.log("USER DETAILS:", a_name, a_pass, a_group);

        //update the special menu items
        RefreshMenu(true);
      }, 500)
      dispatch(getAttendeesThunk());
      dispatch(getMerchOrderThunk());      
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let iv={
    view: view
  }

  let merch:IMerchProps = {handleMerchRequest: handleMerchSubmit, handleClose : handleClose};
  let prompt:IPromptGDPRProps = {handleShow:ShowSettings, handleAllow: AllowGDPR};
  let gdpr:IGDPR = {open_modal: showGDPRSettings, callback_close: gdprShow};

  let bAdmins:Boolean = false;
  
  if(credentials.userName.toLocaleLowerCase()==="hazel.fagon@gmail.com" ||
  credentials.userName.toLocaleLowerCase()==="roy.fagon@googlemail.com")
  {
    //console.log("ADMIN:", credentials.userName);
    bAdmins = true;
  }

  //console.log("VIEW:", view);

  return (
    <div id="main_canvas" className={classes.main_canvas}>
      <ThemeProvider theme={theme}>
      <ToastContainer />
      
      {panel && <DisableView {...cbFunc}/>}

      <FPAppbar {...topBarProps} > 
        <></>
        <div className={classes.main_logo}><img src={logo} height="40px" alt="logo"/>
          {loggedIn ? <Typography className={classes.group_name}>{credentials.groupName}</Typography> : <></>}
        </div>
      </FPAppbar>

      <div className={panel ? "cd-panel-header-open" : "cd-panel-header-close"}>
        <div id="slide-panel" className={classes.panel}>

            <List component="nav">
              {titles.map((item:IMenuItem, i:number) => (
                item.show && <ListItem key={item.key}    
                  id={item.key}       
                  className={clsx({
                    [classes.initial]: item.color === Color.initial,
                    [classes.hilight]: item.color === Color.hilight,
                    [classes.special]: item.color === Color.special,
                    [classes.disable]: item.color === Color.disable,
                  })}
                  onClick={item.subitems.length === 0 ? MenuSelect : undefined}
                  
                >
                    {
                      item.subitems.length === 0 &&
                      <>
                        <ListItemAvatar>
                          {item.icon}
                        </ListItemAvatar>                      
                        <ListItemText>
                          <Typography>
                            {item.title}
                          </Typography>                    
                        </ListItemText>     
                      </>    
                    }

                    {
                      item.subitems.length > 0 &&                    
                        <Accordion
                          key={i}
                          style={{
                            backgroundColor:"#416000",
                            color:"#ffffff",
                          }}                          
                        >
                          <AccordionSummary

                            aria-controls="panel1a-content"
                          >
                            <ListItemAvatar>
                              {item.icon}
                            </ListItemAvatar>                            
                            <Typography>
                              {item.title}
                            </Typography>                    
                          
                          </AccordionSummary>
                            {
                              item.subitems.map((subitem:IMenuItem, ii:number) => (
                                <AccordionDetails key={subitem.key}>

                                  <ListItemAvatar>
                                    {subitem.icon}
                                  </ListItemAvatar>                            
                                  <Typography
                                    id={subitem.key}
                                    onClick={MenuSelect}                                    
                                  >
                                    {subitem.title}
                                  </Typography>  
                                </AccordionDetails>
                              ))
                            }
                          
                        </Accordion>
                    }
                </ListItem>

              ))
              
            }
            </List>
        </div> 
      </div>

      <div id="view_canvas" className={classes.view_canvas}>
        {
          view === View.ABOUT && <AboutUs/>
        }
        {
          view === View.BAR && <Bar/>
        }        
        {
          view === View.FOOD && <Food/>
        }        
        {
          view === View.KARAOKE && <Karaoke/>
        }        
        {
          view === View.DRINK && <Drinks/>
        }
        {
          view === View.MUSIC && <Music/>
        }
        {
          view === View.VIDEO && <Video/>
        }      
        {
          view === View.PICTURE && <Picture/>
        }       
        {
          view === View.MERCH && <Merchandise {...merch}/>
        }       
        {
          view === View.ATTENDEES && <AttendeeList/>
        }          
        {
          view === View.RSVP && <RSVP handleSubmit={handleRSVPSubmit} handleExit={exitApplication}/>
        }                  
        {
          (
            view === View.MAST || 
            view === View.CROW || 
            view === View.RIGGING || 
            view === View.SHIP || 
            view === View.WHEEL || 
            view === View.CANNON || 
            view === View.CORNER
            ) && <Props {...iv}/>
        }             
        {
          view === View.FAQ && <Faq/>
        }      
        {
          view === View.REGISTER && <Login
          appLabel={"JamHaw"}
          appName={props.appName} 
          version={VERSION}
          systemName={"jamhaw"} 
          fp_user={""} //! Tells TypeScript that even though something looks like it could be null, it can trust you that it's not
          //users={(role_users[0] !== undefined) ? role_users : []}
          handleSubmit={handleSubmit}
          handleExit={exitApplication}
        />
        }          
        {
          view === View.INITIAL && !panel && !showGDPRSettings && <>
              <div className={classes.sidebar_right}>
                <div className={classes.contact}><a href="https://www.facebook.com/groups/359381577498099" rel="noreferrer noopener"><img src={contact_face} height="50px" alt="contact_face"/></a></div>
                <div className={classes.contact}><a href="mailto:roy.fagon@ntlworld.com"><img src={contact_mail}  height="50px" alt="contact_mail"/></a></div>
                <div className={classes.contact}><a href="https://chat.whatsapp.com/HMUTJiDbp2cEkG5jVRSqdA"  rel="noreferrer noopener"><img src={contact_what}  height="50px" alt="contact_what"/></a></div>
                {/*<div className="contact"><a download href="https://stiletto.ddns.net/tvworld/mobile/jamhaw.apk"  rel="noreferrer noopener"><img src={contact_app} alt="app"/></a></div>*/}
              </div>      
              {/* <div className={classes.dancer_zone}>
                <div className="dancer1">
                  <img src={dancer1} alt="dancer1" className={classes.dancer1}/>
                </div>
                <div className="dancer2">
                  <img src={dancer2} alt="dancer2" className={classes.dancer2}/>
                </div>              
                <div className="dancer3">
                  <img src={dancer3} alt="dancer3" className={classes.dancer3}/>
                </div>              
                <div className="dancer4">
                  <img src={dancer4} alt="dancer4" className={classes.dancer4}/>
                </div>              
                <div className="dancer5">
                  <img src={dancer5} alt="dancer5" className={classes.dancer5}/>
                </div>    
              </div> */}
              <div className="drinkaware">
                <div className="hazel">
                  <img src={drinkaware} alt="drinkaware"/>
                </div>
            </div>  
            </>
        }
        {/* {
          (!isGDPR && 
            !showGDPRSettings) && 
              <PromptGDPR {...prompt}/>
        } */}
        <GDPR {...gdpr}/>
        {/* <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Note archived"
          action={action}
        />         */}
      </div>
    
      </ThemeProvider>
    </div>
  );
}

export default MainContainer;

