export const ViewTypes = {
  LOGIN_VIEW: "LOGIN_VIEW",
  SCANNING_VIEW: "SCANNING_VIEW",
  CHECK_LIST_VIEW: "CHECK_LIST_VIEW",
  CAM_SCAN: "CAM_SCAN",
  SKU_LIST_VIEW: "SKU_LIST_VIEW",
  COMMENT_VIEW: "COMMENT_VIEW",
  INFO_VIEW: "INFO_VIEW",
  DATE_PICKER_VIEW: "DATE_PICKER_VIEW",
  DETAIL_VIEW: "DETAIL_VIEW",
};
