
export function Gallery2016(){

  return {
  "images": [
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_014.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_023.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_023.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_024.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_024.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_025.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_025.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_026.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_026.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_032.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_033.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_033.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_034.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_034.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_035.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_035.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_036.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_036.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_037.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_037.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_038.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_038.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_039.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_039.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_040.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_040.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_041.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_041.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_042.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_042.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_043.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_043.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_044.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_044.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_045.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_045.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_046.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_046.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_047.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_047.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_048.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_048.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_049.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_049.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_050.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_050.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_051.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_051.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_052.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_052.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_053.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_053.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_054.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_054.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_055.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_055.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_056.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_056.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_057.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_057.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_058.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_058.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_059.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_059.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_060.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_060.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_061.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_061.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_062.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_062.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_063.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_063.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_064.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_064.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_065.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_065.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_066.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_066.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_067.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_067.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_068.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_068.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_069.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_069.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_070.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_070.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_071.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_071.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_072.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_072.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_073.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_073.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_074.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_074.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_075.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_075.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_076.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_076.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_077.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_077.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_078.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_078.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_079.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_079.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_080.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_080.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_081.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_081.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_082.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_082.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_083.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_083.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_084.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_084.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_085.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_085.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_086.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_086.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_087.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_087.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_088.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_088.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_089.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_089.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_090.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_090.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_091.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_091.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_092.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_092.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_093.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_093.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_094.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_094.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2016/LRG_095.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2016/SML_095.jpg"
        }
      ]
    }
}