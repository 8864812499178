import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ILoginResponse } from "../Framework/Login/Login";
import { ELoadingStatus } from "./Enums";
import { AxiosErrorHandler } from "../Framework/AxiosHelper/AxiosHelper";
import { RootState } from "../store";

// Defined for the slice state interface
export interface IMediaState {
  media_loading: ELoadingStatus;
  save_media_loading: ELoadingStatus;
  media_error_msg: string;
  base64: string;
  picture_id: number;
}

const URL = process.env.REACT_APP_BASE_URL;

/**
 * Arguments passed to the getImageThunk
 */
export interface ICameraArgs {
  picid: number;
  credentials: ILoginResponse;
}

/**
 * Arguments passed to the saveImageThunk
 */
export interface ICameraSaveArgs {
  data: string;
  credentials: ILoginResponse;
}

/**
 * Retrieves the base64 image from the database using the image id
 */
export const getImageThunk = createAsyncThunk(
  "spotcheck/media",
  async (cam: ICameraArgs, thunkAPI) => {
    try {
      const response = await axios.get(
        URL + "/api/GetImage/" + cam.picid + "/large",
        {
          auth: {
            username: cam.credentials.user,
            password: cam.credentials.password,
          },
        }
      );
      //console.log("getImageThunk");
      return await response.data.image;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(AxiosErrorHandler(error));
    }
  }
);

function b64toBlob(dataURI: string) {
  //console.log("b64toBlob:", dataURI.length);
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}

/**
 * Saves a blob image to the database returning the new id
 */
export const saveImageThunk = createAsyncThunk(
  "spotcheck/save_media",
  async (cam: ICameraSaveArgs, thunkAPI) => {
    var blob = b64toBlob(cam.data);

    const fdx = new FormData(); // I know, It won't work
    fdx.append("blob", blob);

    //console.log("BLOBX:", blob);

    try {
      const response = await axios.post(URL + "/api/SaveImage", fdx, {
        headers: { "Content-Type": "multipart/form-data;" },
        timeout: 30000,
        auth: {
          username: cam.credentials.user,
          password: cam.credentials.password,
        },
      });
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/**
 * Initial slice state
 */
const initialState: IMediaState = {
  media_loading: ELoadingStatus.idle,
  save_media_loading: ELoadingStatus.idle,
  media_error_msg: "",
  base64: "",
  picture_id: -1,
};

///////////////////////////////////////////////////////////////////////////////////////
const MediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    ResetMediaLoadingStatus: (state) => {
      state.save_media_loading = ELoadingStatus.idle;
      state.media_loading = ELoadingStatus.idle;
    },
  },

  extraReducers: (builder) => {
    /** GET Media **********************************************/
    builder.addCase(getImageThunk.pending, (state) => {
      state.media_loading = ELoadingStatus.loading;
    });

    builder.addCase(getImageThunk.fulfilled, (state, { payload }) => {
      state.base64 = payload[0].base64image;
      state.media_loading = ELoadingStatus.loaded;
    });

    builder.addCase(getImageThunk.rejected, (state, action) => {
      state.media_loading = ELoadingStatus.error;
      state.media_error_msg = action.error.toString();
    });

    /** SAVE Media **********************************************/
    builder.addCase(saveImageThunk.pending, (state) => {
      state.save_media_loading = ELoadingStatus.loading;
    });

    builder.addCase(saveImageThunk.fulfilled, (state, { payload }) => {
      state.picture_id = payload.error_status[0].picture_id;
      state.save_media_loading = ELoadingStatus.loaded;
    });

    builder.addCase(saveImageThunk.rejected, (state, action) => {
      //console.log("Picture Error:", action);
      state.save_media_loading = ELoadingStatus.error;
      state.media_error_msg = action.error.toString();
    });
  },
});

export const { ResetMediaLoadingStatus } = MediaSlice.actions;

export const selectSaveMediaLoadingStatus = (rootState: RootState) => {
  return rootState.media.save_media_loading;
};

export const selectMediaError = (rootState: RootState) => {
  return rootState.media.media_error_msg;
};

/**
 * Gets the BASE64 string of the image
 */
export const selectBase64 = (rootState: RootState) => {
  return rootState.media.base64;
};

/**
 * Gets the return picture id
 */
export const selectPictureId = (rootState: RootState) => {
  return rootState.media.picture_id;
};

export default MediaSlice.reducer;
