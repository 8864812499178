

export function Gallery2013(){

  return {
  "images": [
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_014.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_023.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_023.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_024.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_024.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_025.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_025.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_026.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_026.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2013/IMG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2013/SML_032.jpg"
        }
     ]
    }
}