import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GalleryFood } from './gallery/GalleryFood';
import PictureViewer from './PictureViewer';
import {GetMenutTitle} from './MainLibrary';
import {
  Typography,
} from "@material-ui/core";
//import {IsMobile} from "./MainLibrary";

let ip = {images : GalleryFood().images};

export const Food = () => {
  return (
    <div className="sub-canvas">
      <Box
        sx={{
          justifyContent:"center",
          alignItems:"center",          
          display: 'flex',
          padding: '5px',
          breakInside: 'auto',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 3,
            width: 600,
            height: 500,
          },
        }}
      >
        <Paper elevation={4}
          style={{
              padding: 10,
              border: "1px solid black",
              overflow: 'auto',            
              scrollbarWidth: "none",
            }}
        >
          <b>The Food</b><br/><br/>
              I think I can say that the thing people look forward to the most would be the catering.<br/><br/> 
              At the JamHaw event we do our own catering.<br/><br/> 
              Me and Hazel will be working hard on the day to deliver a huge selection of Caribbean 
              and traditional hot dishes along with the BBQ grill that will be open for use during the whole event.<br/><br/> 
              Some of the dishes take about four days to prepare and the rest is done the night before or early that morning.<br/><br/> 
              The food is kept hot throughout the event by numereous hot-plates and Chafer dishes.<br/><br/> 
              We cater for both carnivore and vegan palettes.<br/><br/> 
              You can also bring your own dietry requirements if you so wish. You can see a list of some of our 
              dishes on the right-hand side of this page.<br/><br/>
        </Paper>
        <Paper elevation={4}
          style={{
              padding: 10,
              border: "1px solid black",
              scrollbarWidth: "none",
              overflow: 'auto'            
  
            }}>
            <PictureViewer {...ip} /> 
            <br/>
            <Typography  
              style={{
                fontWeight: 800,
              }}
              variant="h6">
                {GetMenutTitle("FOOD")}
            </Typography>              

        </Paper>
  
      </Box>
     
    </div>
  );
}