

export function GalleryCANNONS(){
  return {
    "images": [
      
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_001.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_002.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_003.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_004.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_005.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_006.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_007.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_008.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_009.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_010.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_011.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_012.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_013.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_014.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_015.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_016.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_017.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_018.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_019.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_020.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_021.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_022.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_023.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_024.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_025.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_026.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_027.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_028.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_029.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_030.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_031.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_032.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_033.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_034.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_035.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_036.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_037.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_038.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_039.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCANNONS/IMG_040.jpg"}
    ]
  }
}
