import '../css/App.css';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {IImage} from './MainInterface';
import { GalleryMAST }       from './gallery/GalleryMAST';
import { GalleryCANNONS }    from './gallery/GalleryCANNONS';
import { GalleryRIGGING }    from './gallery/GalleryRIGGING';
import { GalleryCROWS_NEST } from './gallery/GalleryCROWS_NEST';
import { GalleryWHEEL }      from './gallery/GalleryWHEEL';
import { GalleryCPT_CORNER } from './gallery/GalleryCPT_CORNER';
import { GallerySHIP }       from './gallery/GallerySHIP';
import PictureViewerX from './PictureViewerX';
import {IView} from './MainContainer';
import {GetMenutTitle} from './MainLibrary';
//import {IsMobile} from "./MainLibrary";
import {
  View,
} from '../redux/JamHawInterface';

import {
  Typography,
} from "@material-ui/core";

var img:IImage[] = []; 

export function Props(vw:IView): JSX.Element {

    switch(vw.view){
      case View.MAST: img = GalleryMAST().images; break;
      case View.CANNON: img = GalleryCANNONS().images; break;
      case View.RIGGING: img = GalleryRIGGING().images; break;
      case View.CROW: img = GalleryCROWS_NEST().images; break;
      case View.WHEEL: img = GalleryWHEEL().images; break;
      case View.CORNER: img = GalleryCPT_CORNER().images; break;
      case View.SHIP: img = GallerySHIP().images; break;
    }
    
    let ip = {images : img};

    return (
      <div className="sub-canvas">
        <Box
          sx={{
            justifyContent:"center",
            alignItems:"center",          
            display: 'flex',
            padding: '5px',
            breakInside: 'auto',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 3,
              width: 600,
              height: 500,
            },
          }}
        >
          <Paper elevation={4}
              style={{
                  padding: 10,
                  border: "1px solid black",
                  scrollbarWidth: "none",
                  overflow: 'auto'            
      
                }}>
                <PictureViewerX {...ip} /> 
                <br/>
                <Typography  
                  style={{
                    fontWeight: 800,
                  }}
                  variant="h6">
                    {GetMenutTitle(View[vw.view])}
                </Typography>                 
            </Paper>      
        </Box>
      </div>)
}

export default Props;
