import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
//import {IsMobile} from "./MainLibrary";

export const AboutUs = () => {
  return (
    <div className="sub-canvas">
      <Box
        sx={{
          justifyContent:"center",
          alignItems:"center",          
          display: 'flex',
          padding: '5px',
          breakInside: 'auto',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 3,
            width: 600,
            height: 520,
          },
          
        }}
      >
        <Paper elevation={4}
          style={{
              padding: 10,
              border: "1px solid black",
              overflow: 'auto'
            }}
          >
          <b>About Us</b><br/><br/>
          Jam-Haw is a private party created by Roy & Hazel Fagon.<br/><br/>
              With the long success of the Halloween parties that ran from 2000 to 2015 it was time to call it a day and
              instead come up with something that was just as fun but easy to maintain.<br/><br/>
              Having travelled to the Hawaiian islands and the Caribbean we thought it would be a good idea to celebrate
              both cultures, so we created Jam-Haw.<br/><br/>
              At Jam-Haw you can join in on our celebration that brings family, friends and associates together
              for one day that consists of drinking, dancing and singing.<br/><br/>
              Enjoy the Jamaican delights of hot cooked food and strong rum and the Hawaiian culture of relaxing
              with a miriad of strong cocktails and to top it all we also have a large Karaoke venue.<br/><br/>
              The food is freshly cooked by us and the outdoor bar consists of two professional
              Mixologists which we hire every year.<br/><br/>At night time you can dance the evening away to our indoor/outdoor disco.<br/><br/>
        </Paper>
      </Box>
    </div>
  );
}