import { ILoginResponse } from "../Framework/Login/Login";
import { ELoadingStatus } from "./Enums";

export enum View {
  "INITIAL",
  "ABOUT",
  "BAR",
  "FOOD",
  "DRINK",
  "MUSIC",
  "KARAOKE",
  "GALLERY",
  "VIDEO",
  "PICTURE",
  "PROPS",
  "MAST",
  "CANNON",
  "RIGGING",
  "CROW",
  "WHEEL",
  "CORNER",
  "SHIP",
  "FAQ",  
  "CONTACT",
  "MERCH",
  "ATTENDEES",
  "REGISTER",
  "RSVP",
  "LOGOUT",  
}

export interface IRsvpProps {
  handleSubmit: (total: number) => void;
  handleExit: () => void;
}

export interface IMerchProps {
  handleMerchRequest: () => void;
  handleClose:  () => void;
}
  
export interface IDialogManager {
  classx? : any;
  title: string;
  message: string; 
  button1: string; 
  button2?: string;
  button3?: string;
  exceptionType?: string;
  viewType?: string;
  data? : any;
}

export interface IAttendee{
  count: number,
  group: string,
  email: string,
}

export interface IImage {
    "original": string,
    "thumbnail": string
}

export interface IAttendeeResponse {
    "attendee": string,
    "response": string
}


export interface IAttendeeResponse {
    "attendee": string,
    "response": string
}

export interface IValidationResponse {
  "response": string,
  "validation": string
}

export interface IMerchandise {
    "email": string, 
    "small": number,
    "medium": number,
    "large": number,
    "xlarge": number,
}

export interface IMerchandiseResponse {
  "response": string,
}

export interface ICredentials {
  passWord: string,
  userName: string,    
  groupName: string,    
}


export interface IPromptGDPRProps {
  handleShow: () => void;
  handleAllow: () => void;
}

export interface IGDPR {
  "open_modal": boolean,
  "callback_close": (event: any) => void;
}

// Define a type for the slice state
export interface IJamHawState {
  
  validate_loading: ELoadingStatus;
  attendees_loading: ELoadingStatus;
  attendees_response_loading: ELoadingStatus;
  merchandise_loading: ELoadingStatus;
  merchandise_response_loading: ELoadingStatus;

  error_validate_msg: string;
  error_attendees_msg: string;
  error_attendees_response_msg: string;
  error_merchandise_msg: string;
  error_merchandise_response_msg: string;
  
  isOK: boolean;
  isCANCEL: boolean;
  dialogMessage: IDialogManager;
  showDialog: boolean; 
  view: string,
  loggedIn: boolean,
  gdpr: boolean,
  galleryMode: boolean,
  showMenu: boolean,
  showSidebar: boolean,
  showFooter: boolean,
  credentials: ICredentials, 
  
  attendees: IAttendee[],
  attendees_response: IAttendeeResponse | undefined,

  merchandise: IMerchandise[];
  merchandise_response: IMerchandiseResponse | undefined,

  validate: IValidationResponse,
  gallery: IImage[],
  passwordFail: boolean ;
  emailFail: boolean ;
  
}

