// tslint:disable:no-console
// tslint:disable:no-trailing-whitespace
// tslint:disable:no-irregular-whitespace

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./css/App.css";

import { Login } from "./Framework/Login/Login";
import MainContainer from "./components/MainContainer";
import {
  UpdateCredentials,
  //selectCredentialsReady,
} from "./redux/SessionSlice";

import SearchIcon from "@material-ui/icons/Search";
import { useAppSelector } from "./Framework/hooks";

var intervalId = null;
var timeStart = 0;
var timeCurrent = 0;

/////////////////////////////////////////////////////////////////////////////////
function App() {
  const dispatch = useDispatch();

  /**
   * Runs once at page load to retrieve the stored user name and password
   */
  useEffect(() => {
    console.log("React v" + React.version);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="App">
        <div className="MainApp">
          <MainContainer />
        </div>
    </div>
  );
}

export default App;
