// import LockIcon from '@mui/icons-material/Lock';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import placed from "../images/s.png";
// import completed from "../images/status_completed.png";
// import shipped from "../images/status_shipped.png";
// import deleted from "../images/status_deleted.png";
// import backOrdered from "../images/status_back_ordered.png";
// import parked from "../images/status_parked.png";
// import dispatched from "../images/dispatched.png";

export function Status(_props:any): JSX.Element {	
	//console.log("Status:", _props);

    return (
        <div style={{display: "inline-flex"}}>
			{/* { _props.status==="locked" && <div style={{display: "inline-flex", marginLeft: "15px", cursor: "pointer"}}><LockIcon color="error"/></div>}
			{ _props.status==="unlocked" && <div style={{display: "inline-flex", marginLeft: "15px", cursor: "pointer"}}><LockOpenIcon color="success"/></div>}			
			
			{ _props.status==="Placed" 		&& <div style={{display: "inline-flex", marginTop: "2px"}}><img src={placed} height={22} alt={"Placed"} /></div>}
			{ _props.status==="Completed" 	&& <div style={{display: "inline-flex", marginTop: "2px"}}><img src={completed} height={22} alt={"Completed"} /></div>}
			{ _props.status==="Parked" 		&& <div style={{display: "inline-flex", marginTop: "2px"}}><img src={parked} height={22} alt={"parked"} /></div>}
			{ _props.status==="Deleted" 	&& <div style={{display: "inline-flex", marginTop: "2px"}}><img src={deleted} height={22} alt={"deleted"} /></div>}
			{ _props.status==="Shipped" 	&& <div style={{display: "inline-flex", marginTop: "2px"}}><img src={shipped} height={22} alt={"shipped"} /></div>}
			{ _props.status==="Dispatched" 	&& <div style={{display: "inline-flex", marginTop: "2px"}}><img src={dispatched} height={22} alt={"dispatched"} /></div>}
			{ _props.status==="BackOrdered" && <div style={{display: "inline-flex", marginTop: "2px"}}><img src={backOrdered} height={22} alt={"backOrdered"} /></div>} */}
		</div>
    )
}