import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";

import {
  ILoginState,
  ELoadingStatus,
  IRoleArgs,
  IValidateArgs,
} from "./LoginInterface";

import { Buffer } from "buffer";

const URL = process.env.REACT_APP_BASE_URL;

////////////////////////////////////////////////////////
// SYSTEM
////////////////////////////////////////////////////////
/**
 * Retrieves the platform identifier e.g. DEV, UAT, LIVE
 */
export const getSystemsThunk = createAsyncThunk(
  "login/system",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        URL + "/api/GetSystemName/LIVE_fp_warehouse"
      );

      return await response.data.Table;
    } catch (error: any) {
      let str = String(error.response.data.ExceptionMessage).split("\n")[0];
      return thunkAPI.rejectWithValue(str);
    }
  }
);

////////////////////////////////////////////////////////
// APPLICATION
////////////////////////////////////////////////////////
/**
 * Retrieves the application version details
 */
export const getApplicationsThunk = createAsyncThunk(
  "system/application",
  async (system_name: string, thunkAPI) => {
    let payLoad = {
      app_name: system_name,
      running_version: process.env.REACT_APP_VERSION,
    };

    try {
      const response = await axios.post(
        URL +
          "/api/na_stored_procedure/LIVE_fp_warehouse/CORE_GLOBAL_GetAppVersion",
        JSON.stringify(payLoad)
      );

      return await response.data.Table;
    } catch (error: any) {
      let str = String(error.response.data.ExceptionMessage).split("\n")[0];
      return thunkAPI.rejectWithValue(str);
    }
  }
);

////////////////////////////////////////////////////
// ROLE SUPERVISOR
////////////////////////////////////////////////////
/**
 * returns supervisory level roles
 */
export const getRoleSupervisorsThunk = createAsyncThunk(
  "login/role_user_supervisor",
  async (props: IRoleArgs, thunkAPI) => {
    try {
      const response = await axios.get(URL + "/api/getroleusers/" + props.role);

      return await response.data.Table;
    } catch (error: any) {
      let str = String(error.response.data.ExceptionMessage).split("\n")[0];
      return thunkAPI.rejectWithValue(str);
    }
  }
);

////////////////////////////////////////////////////
// ROLE USER
////////////////////////////////////////////////////
/**
 * returns the role users
 */
export const getRoleUsersThunk = createAsyncThunk(
  "login/role_user",
  async (props: IRoleArgs, thunkAPI) => {
    try {
      const response = await axios.get(URL + "/api/getroleusers/" + props.role);

      return await response.data.Table;
    } catch (error: any) {
      let str = String(error.response.data.ExceptionMessage).split("\n")[0];
      return thunkAPI.rejectWithValue(str);
    }
  }
);

////////////////////////////////////////////////////
// VALIDATE
////////////////////////////////////////////////////
/**
 * Validates the credentials
 */
export const getValidatesThunk = createAsyncThunk(
  "login/validate",
  async (props: IValidateArgs, thunkAPI) => {
    try {
      axios.defaults.headers.common = {
        Authorization:
          "Basic " +
          Buffer.from(props.user + ":" + props.password).toString("base64"),
        "Content-Type": "application/json",
      };

      const response = await axios.get(URL + "/Login");

      return await response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error });
    }
  }
);

////////////////////////////////////////////////////
export const initialState: ILoginState = {
  systems: [],
  system_type: "UNKNOWN",
  system_loading: ELoadingStatus.idle,
  system_error_msg: "",

  //APPLICATION
  application_loading: ELoadingStatus.idle,
  application_error_msg: "",
  applications: [],
  current_version: "0.0.0",
  min_version: "0.0.0",

  //ROLE SUPERVISOR
  role_supervisor_loading: ELoadingStatus.idle,
  role_supervisor_error_msg: "",
  role_supervisors: [],

  //ROLE USER
  role_user_loading: ELoadingStatus.idle,
  role_user_error_msg: "",
  role_users: [],

  //USER
  user_loading: ELoadingStatus.idle,
  user_error_msg: "",

  //VALIDATE
  validate_loading: ELoadingStatus.idle,
  validate_error_msg: "",
  confirmed: "",
};

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    ////////////////////////////////////////////////////
    // SYSTEM
    ////////////////////////////////////////////////////
    builder.addCase(getSystemsThunk.pending, (state) => {
      state.systems = [];
      state.system_loading = ELoadingStatus.loading;
    });

    builder.addCase(getSystemsThunk.fulfilled, (state, { payload }) => {
      state.systems = payload;
      state.system_loading = ELoadingStatus.loaded;
    });

    builder.addCase(getSystemsThunk.rejected, (state, action) => {
      if (action.payload !== undefined) {
        state.system_loading = ELoadingStatus.error;
        if (action.error.message !== undefined) {
          state.system_error_msg = String(action.payload);
          state.system_type = state.system_error_msg;
        }
      } else {
        state.system_loading = ELoadingStatus.error;
        if (action.error.message !== undefined) {
          state.system_error_msg = action.error.message;
        }
      }
    });

    ////////////////////////////////////////////////////
    // APPLICATION
    ////////////////////////////////////////////////////
    builder.addCase(getApplicationsThunk.pending, (state) => {
      //console.log("ApplicationSlice-> loading");
      state.applications = [];
      state.application_loading = ELoadingStatus.loading;
    });

    builder.addCase(getApplicationsThunk.fulfilled, (state, { payload }) => {
      state.applications = payload;
      state.application_loading = ELoadingStatus.loaded;
    });

    builder.addCase(getApplicationsThunk.rejected, (state, action) => {
      //console.log("ERROR:");
      if (action.payload !== undefined) {
        //console.log("extraReducers[ERROR CUSTOM]", action.payload);
        state.application_loading = ELoadingStatus.error;
        if (action.error.message !== undefined) {
          state.application_error_msg = String(action.payload);

          if (state.application_error_msg.indexOf("current version") !== -1) {
            //contains valid version check message
            console.log(state.application_error_msg); //upgrade notice goes here
            const regex = /\d+(\.\d+)+/g;
            var dx = state.application_error_msg.match(regex);
            state.current_version = String(dx![0]);
            state.min_version = String(dx![1]);
            //console.log("VERSION:", state.current_version, state.min_version);
          }
        }
      } else {
        //console.log("extraReducers[ERROR]", action.error);
        state.application_loading = ELoadingStatus.error;
        if (action.error.message !== undefined) {
          state.application_error_msg = action.error.message;
        }
      }
    });

    ////////////////////////////////////////////////////
    // ROLE SUPERVISOR
    ////////////////////////////////////////////////////
    builder.addCase(getRoleSupervisorsThunk.pending, (state) => {
      //console.log("RoleSupervisorSlice-> loading");
      // state.master_codes = [];
      state.role_supervisor_loading = ELoadingStatus.loading;
    });

    builder.addCase(getRoleSupervisorsThunk.fulfilled, (state, { payload }) => {
      state.role_supervisors = payload;
      state.role_supervisor_loading = ELoadingStatus.loaded;
    });

    builder.addCase(getRoleSupervisorsThunk.rejected, (state, action) => {
      //console.log("extraReducers[mc]-> error", action);
      state.role_supervisor_loading = ELoadingStatus.error;
      state.role_supervisor_error_msg = action.error.toString();
    });

    ////////////////////////////////////////////////////
    // ROLE USER
    ////////////////////////////////////////////////////
    builder.addCase(getRoleUsersThunk.pending, (state) => {
      //console.log("RoleUserSlice-> loading");
      state.role_users = [];
      state.role_user_loading = ELoadingStatus.loading;
    });

    builder.addCase(getRoleUsersThunk.fulfilled, (state, { payload }) => {
      state.role_users = payload;
      state.role_user_loading = ELoadingStatus.loaded;
    });

    builder.addCase(getRoleUsersThunk.rejected, (state, action) => {
      //console.log("ERROR:");
      if (action.payload !== undefined) {
        //console.log("extraReducers[ERROR CUSTOM]", action.payload);
        state.role_user_loading = ELoadingStatus.error;
        if (action.error.message !== undefined) {
          state.role_supervisor_error_msg = String(action.payload);
        }
      } else {
        //console.log("extraReducers[ERROR]", action.error);
        state.role_user_loading = ELoadingStatus.error;
        if (action.error.message !== undefined) {
          state.role_user_error_msg = action.error.message;
        }
      }
    });

    ////////////////////////////////////////////////////
    // VALIDATE
    ////////////////////////////////////////////////////
    builder.addCase(getValidatesThunk.pending, (state) => {
      //console.log("ValidateSlice-> loading");
      state.confirmed = "";
      state.validate_loading = ELoadingStatus.loading;
    });

    builder.addCase(getValidatesThunk.fulfilled, (state, { payload }) => {
      //console.log("ValidateSlice-> loaded", payload.test);
      state.confirmed = "PASS";
      state.validate_loading = ELoadingStatus.loaded;
    });

    builder.addCase(getValidatesThunk.rejected, (state, action) => {
      //console.log("extraReducers[mc]-> error", action);
      state.validate_loading = ELoadingStatus.error;
      state.confirmed = "FAIL";
      state.validate_error_msg = action.error.toString();
    });
  },
});

//const selectSelf = (state: any) => state;

////////////////////////////////////////////////////////////////////
//SYSTEM
export const selectSystems = (rootState: RootState) => {
  return rootState.login.systems;
};
export const selectSystemError = (rootState: RootState) => {
  return rootState.login.system_error_msg;
};

export const selectSystemLoading = (rootState: RootState) => {
  return rootState.login.system_loading;
};

////////////////////////////////////////////////////////////////////
//APPLICATION
export const selectApplications = (rootState: RootState) => {
  return rootState.login.applications;
};

export const selectApplicationError = (rootState: RootState) => {
  return rootState.login.application_error_msg;
};

export const selectApplicationLoading = (rootState: RootState) => {
  return rootState.login.application_loading;
};

export const selectMinVer = (rootState: RootState) => {
  return rootState.login.min_version;
};

export const selectCurVer = (rootState: RootState) => {
  return rootState.login.current_version;
};

////////////////////////////////////////////////////////////////////
//ROLE SUPERVISOR
export const selectRoleSupervisors = (rootState: RootState) => {
  return rootState.login.role_supervisors;
};

export const selectRoleSupervisorsError = (rootState: RootState) => {
  return rootState.login.role_supervisor_error_msg;
};

export const selectRoleSupervisorLoading = (rootState: RootState) => {
  return rootState.login.role_supervisor_loading;
};

////////////////////////////////////////////////////////////////////
//ROLE USER
export const selectRoleUsers = (rootState: RootState) => {
  return rootState.login.role_users;
};

export const selectRoleError = (rootState: RootState) => {
  return rootState.login.role_user_error_msg;
};

export const selectRoleUserLoading = (rootState: RootState) => {
  return rootState.login.role_user_loading;
};

////////////////////////////////////////////////////////////////////
//USER

////////////////////////////////////////////////////////////////////
//VALIDATE
export const selectValidate = (rootState: RootState) => {
  return rootState.login.confirmed;
};

export const selectValidateError = (rootState: RootState) => {
  return rootState.login.validate_error_msg;
};

export const selectValidateLoading = (rootState: RootState) => {
  return rootState.login.validate_loading;
};

export default LoginSlice.reducer;
