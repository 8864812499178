import {IImage} from '../MainInterface';

export function Gallery2022(){
  
  let images:IImage[] = [
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_014.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_023.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_023.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_024.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_024.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_025.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_025.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_026.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_026.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_032.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_033.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_033.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_034.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_034.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_035.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_035.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_036.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_036.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_037.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_037.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_038.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_038.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_039.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_039.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_040.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_040.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_041.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_041.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_042.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_042.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_043.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_043.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_044.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_044.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_045.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_045.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_046.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_046.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_047.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_047.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_048.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_048.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_049.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_049.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_050.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_050.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_051.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_051.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_052.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_052.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_053.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_053.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_054.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_054.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_055.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_055.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_056.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_056.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_057.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_057.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_058.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_058.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_059.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_059.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_060.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_060.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_061.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_061.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_062.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_062.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_063.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_063.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_064.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_064.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_065.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_065.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_066.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_066.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_067.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_067.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2022/IMG_068.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2022/SML_068.jpg"
        }
        ];
    return {     
        images   
    } 
}