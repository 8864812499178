import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';

export function	GetHeaderStatus(attr){

	var json_status = {
		"NP" : "No Purchase Order",
		"ZZ" : "No Delivery Item(s)",
		"QM" : "Qty Mismatch on Purchase []",
		"UP" : "No match in Purchase []",
		"ZP" : "No Purchase Item(s) Found",
		"RC" : "Item(s) Reconciled",
		"FL" : "FILED: ",
		"UC" : "No match in collection []",
		"NC" : "No Collection Note",			
		"NI" : "No attached items",
		"ZI" : "No Invoice",
		"UI" : "No matching item(s) in Invoice[]",
		"XX" : "Pending",
	}

	return json_status[attr];
}

export function	GetDate(dtx){
	//1999-04-03T00:00:00Z
	//var event = new Date(dtx);
	//return event.toLocaleDateString('gb-GB');
	return dtx.substr(8,2)+"/"+dtx.substr(5,2)+"/"+dtx.substr(0,4);
}

const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .${gridClasses.row}.even`]: {
	  backgroundColor: theme.palette.grey[200],
	  '&:hover, &.Mui-hovered': {
		backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
		'@media (hover: none)': {
		  backgroundColor: 'transparent',
		},
	  },
	  '&.Mui-selected': {
		backgroundColor: alpha(
		  theme.palette.primary.main,
		  ODD_OPACITY + theme.palette.action.selectedOpacity,
		),
		'&:hover, &.Mui-hovered': {
		  backgroundColor: alpha(
			theme.palette.primary.main,
			ODD_OPACITY +
			  theme.palette.action.selectedOpacity +
			  theme.palette.action.hoverOpacity,
		  ),
		  // Reset on touch devices, it doesn't add specificity
		  '@media (hover: none)': {
			backgroundColor: alpha(
			  theme.palette.primary.main,
			  ODD_OPACITY + theme.palette.action.selectedOpacity,
			),
		  },
		},
	  },
	},
  }));