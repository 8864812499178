import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GalleryKaraoke } from './gallery/GalleryKaraoke';
import PictureViewer from './PictureViewer';
import {GetMenutTitle} from './MainLibrary';
import {
  Typography,
} from "@material-ui/core";
//import {IsMobile} from "./MainLibrary";

let ip = {images : GalleryKaraoke().images};

export const Karaoke = () => {
  return (
    <div className="sub-canvas">
      <Box
        sx={{
          justifyContent:"center",
          alignItems:"center",          
          display: 'flex',
          padding: '5px',
          breakInside: 'auto',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 3,
            width: 600,
            height: 500,
          },
        }}
      >
        <Paper elevation={4}
          style={{
              padding: 10,
              border: "1px solid black",
              scrollbarWidth: "none",
              overflow: 'auto'            

            }}
        >
          <b>Karaoke</b><br/><br/>
          Karaoke was invented in Japan in 1971, where Daisuke Inoue created the first karaoke-style machine.<br/><br/> 
          It quickly spread through the rest of Asia, taking hold of the world's clubs and lounges by the 1990s.<br/><br/>
          So that's the history bit out of the way.<br/><br/> 
          At JamHaw you have a fully functioning Karaoke system which is situated in "The Cave" 
          where you can sing to your hearts content. This is a fully equipped Karaoke with the latest hardware 
          and software to make you karaoke experience memorable.<br/><br/>
          We use the famous online service "Lucky Voice" 
          to provide all the songs.<br/><br/>
          click here to see a list of songs or download the pdf.<br/><br/>
        </Paper>
        <Paper elevation={4}
            style={{
                padding: 10,
                border: "1px solid black",
                scrollbarWidth: "none",
                overflow: 'auto'            
    
              }}>
              <PictureViewer {...ip} /> 
              <br/>
              <Typography  
                style={{
                  fontWeight: 800,
                }}
                variant="h6">
                  {GetMenutTitle("KARAOKE")}
              </Typography>              
          </Paper>      
      </Box>
    </div>    
  );
}