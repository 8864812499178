import React, { useEffect } from "react";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import Button from '@mui/material/Button';

/**
 * A MAterial UI appbar thats locked to the top of the screen.
 */
import { SxProps } from '@mui/system';
import { 
  GridColDef, 
  GridValidRowModel, 
} from '@mui/x-data-grid';

import {GetHeaderStatus} from '../../components/Views/TOO/Tools';

import {Status} from '../../components/Status';
import {GetDate, GetAWS_Date} from '../../components/MainLibrary';

import {
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

import { 
  //IMenuItem,
  IMenuProp,
} from "../../redux/IItemInterface";


import clsx from "clsx";

//Styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  magno_grid_canvas: {
    marginTop: "5px",
    width: "calc(100% - 6px)",
    height: "calc(100% - 7px)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "silver",    
    overflowX: "scroll",
    //borderRadius: "6px",
  },
  magno_grid_columns: {
    display: "flex",
    width: "calc(100% - 19px)",
    height: "46px",
    //background: "#c6ced6",
    backgroundColor: "#92D050",
  },
  magno_grid_columns_mobile: {
    display: "inline-flex",
    //width: "100%",
    height: "23px",
    fontSize: "12px",
//    color: "red",
    //background: "#c6ced6",
    backgroundColor: "#92D050",
  },  
  magno_grid_rows: {
    width: "100%",
    height:  "calc(100% - 60px)",
    display: "inline-block",
    overflowY: "scroll", 
  },
  magno_grid_rows_mobile: {
    
    //width: "100%",
    height:  "calc(100% - 30px)",
    display: "inline-block",
    overflowY: "scroll", 
  },  
  magno_grid_row_menu: {
    width: "100%",
    display: "inline-block",
  },
  magno_grid_row_selected:{
    fontWeight: "bold"
  },
  magno_grid_row: {
    width: "100%",
    height: "38px",
    lineHeight: "40px",
  },  
  magno_grid_row_mobile: {
    
    width: "100%",
    height: "28px",
    lineHeight: "40px",
  },    
  magno_grid_row_col: {
    
    display: "inline-flex",
    width: "100%",
    height: "38px",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#e0e0e0",     
  },  
  magno_grid_row_col_mobile: {
    display: "inline-flex",
    width: "100%",
    height: "26px",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "#e0e0e0",     
  },
  col_cell: {
    
    height: "46px",
    lineHeight: "44px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis", 
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",    
    fontFamily: "sans-serif",
  },
  col_cell_mobile: {
    height: "28px",
    lineHeight: "22px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis", 
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",    
    fontFamily: "sans-serif",
  },  
  row_cell_highlight: {
    height: "38px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis", 
    backgroundColor: "#B5D1E8", 
    fontFamily: "sans-serif",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",     
  },  
  row_cell_highlight_mobile: {
    height: "19px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis", 
    backgroundColor: "#B5D1E8", 
    fontFamily: "sans-serif",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",     
  },  
  row_cell_odd: {
    height: "38px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis",  
    width: "calc(100% - 16px)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",  
  },   
  row_cell_odd_mobile: {
    height: "28px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis",  
    width: "calc(100% - 16px)",
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",  
  },    
  row_cell_even: {
    height: "38px",
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis",  
    borderRightStyle: "solid",
    borderRightWidth: "1px",
    borderRightColor: "white",  
  },  
  col_menu: {
    paddingLeft: "6px",
    backgroundColor: "#ffffff", 
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "silver",    
    // borderRadius: "6px",    
  },     
  /*
  row_cell_odd: {
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis",  
    backgroundColor: "#EEEEEE",
  },
  row_cell_even: {
    paddingLeft: "6px",
    overflow: "hidden",  
    textOverflow: "ellipsis", 
    backgroundColor: "#FFFFFF", 
  }, */

  icon:{
    marginTop: "6px",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "red",     
  },
  icon_mobile:{
    marginTop: "0px",
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "red",     
  },  
  pointer:{
    cursor: "pointer",
  }  
}));

export interface IMagnoGridProps {
  sx?: SxProps<Theme>
  rowHeight: number;
  columns: GridColDef<GridValidRowModel>[];
  onRowClick?: (event : any) => void;
  onIconClick?: (event : any) => void;
  menuItems?: IMenuProp;  
  selectIndex?: number;
  rows: readonly GridValidRowModel[];
  children?: JSX.Element[]; //This is required to stop error message when trying to call props.children within the MagnoGrid scope (go figure)
  objectId: string;
  disableHover?: boolean;
  isMobile?: boolean;
}

export interface IMagnoGridIndexedProps {
  magno_props: IMagnoGridProps;
  index: number;
}

//var select = -1;
//var mnu = false;
/**
 * MagnoGrid
 * @param props : IMagnoGridProps
 * @returns  JSX.Element
 */
const MagnoGrid = (
  props: IMagnoGridProps = {
    //define the default props here so people can do <MagnoGrid/> and just get a default bar that works.
    rows: [],
    rowHeight: 38,
    columns: [],
    selectIndex: -1,
    objectId: "NS",
    disableHover: false,
  }
): JSX.Element => {
  const [hoverIndex, setHoverIndex] = React.useState(-1);
  //Grab our styles at the start once so its always done in the same order.
  const classes = useStyles();

  ////////////////////////////////////////////////////////////////////////////////////
  const[showMenu, setShowMenu] = React.useState(false);
  const[mnu, setMnu] = React.useState(false);
  const[select, setSelect] = React.useState(-1);
  const handleIconClick = (event:any) => {
    //event.stopPropagation();
    //console.log("handleIconClick:", event.currentTarget.id, mnu);
    if(props.onIconClick !== undefined)
    {
      let action = String(event.currentTarget.id).split("_")[0];
      let index = Number(String(event.currentTarget.id).split("_")[1]);
      
      setMnu(true);

      //console.log("handleIconClick-index:", index);

      if(action === "menu")
      {
        if(select === index)
        {
          setShowMenu(!showMenu);
        }
        
        if(select !== index)
        {
          if(select === -1) //Close if already open
          {
            //select = index; 
            setSelect(index);
            setShowMenu(true);
            let idd = event.currentTarget.id;
            if (document.getElementById(idd) !== null) {
              document.getElementById(idd)!.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
              });
            }              
            return;
          }
          else //Close if already open
          {
            if(showMenu)
            {  
              setShowMenu(false);
              console.log("**CLOSING**");
              //props.menuItems?.closeMenu(event);
            }

            //select = index; 
            setSelect(index);
            //props.itemIndex = index;
            setShowMenu(true);
            let idd = event.currentTarget.id;
            if (document.getElementById(idd) !== null) {
              document.getElementById(idd)!.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
              });
            }               
            if(props.onRowClick !== undefined)
            {

              props.onRowClick(event);
              
            }
          }
        }
      }
      else
      {      
        setSelect(hoverIndex);
        props.onIconClick(event);
      }
    }
  };

 
  const handleRowClick = (event:any) => {
    //console.log("handleRowClick:", event.currentTarget.id);
    if(mnu)
    {

      setMnu(false);
      return;
    }

    

    let index = Number(String(event.currentTarget.id).split("_")[2]);
    //console.log("handleRowClick:", event.currentTarget.id, index);

    if(index !== hoverIndex)
    {
      setShowMenu(false);
      //console.log("**CLOSING**");
      //props.menuItems?.closeMenu(event);      
    }

    //setHoverIndex(index);
    //console.log("handleRowClick", event.currentTarget.id);
    if(props.onRowClick !== undefined)
    {
      index = Number(String(event.currentTarget.id).split("_")[3]);
      //console.log( index);      
      props.onRowClick(event);
      setSelect(index);
      //props.itemIndex = index;
    }
  }
  
  // const handleMenuClick = (event:any) => {
  //   props.menuItems?.menuItemClick(event);
  //   setShowMenu(false);
  //   setMnu(false);
  // }

  ///////////////////////////////////////////////////////////////
  useEffect(() => {
    if(props.selectIndex )
    {
      //console.log("selectIndex:", props.selectIndex, select, hoverIndex);
      setHoverIndex(props.selectIndex);
      //let idd = "row_"+ props.selectIndex;
      setSelect(props.selectIndex);

      if(props.selectIndex === -1 && select !== -1)
      {
        setSelect(-1);
      }
      // THIS CODE WILL FUCK ABOUT WITH THE RENDERING AND MOVE FROM ORIGINAL LOCATION
      // if (document.getElementById(idd) !== null) {
      //   document.getElementById(idd)!.scrollIntoView({
      //     behavior: "auto",
      //     block: "center",
      //     inline: "center",
      //   });
      // }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  if(props.selectIndex === -1 && select !== -1 && props.objectId==="SUB_ORDERS_VIEW") //Turn off 
  {
    //console.log("*selectIndex*:", props.selectIndex);
    setSelect(-1);
  }
  // }
  //console.log("*selectIndex*:", props.objectId, props.selectIndex, hoverIndex);
  return (
    <div className={classes.magno_grid_canvas}>
      <div className={ !props.isMobile ? classes.magno_grid_columns : classes.magno_grid_columns_mobile}>
        {
          props.columns.map((value: GridColDef<GridValidRowModel>, index: number, array: GridColDef<GridValidRowModel>[]): any =>
          (
            <div 
              key={index} 
              className={!props.isMobile ? classes.col_cell : classes.col_cell_mobile} 
              style={{
                width: value.width+"px",
                paddingRight: ((value.type === "number" || value.type === "number2" || value.type === "number3") ? "3px" : "0px"), 
                textAlign: (
                  value.type === "sum"  || (value.type === "number")
                    ? "center"
                    : (value.type !== "number" && value.type !== "number2" && value.type !== "number3"  )
                      ? "left" 
                      : "right"), 
              }}
            ><span>{value.headerName}</span></div>
          ))
        }
      </div>
      <div id="magno_grid_rows" className={!props.isMobile ? classes.magno_grid_rows : classes.magno_grid_rows_mobile} style={{}}>
        {
          props.rows.map((row: GridValidRowModel, idx:number): any =>
              (
                <div 
                  id={"rw_"+row["id"]+"_"+idx} 
                  key={"rw_"+idx} 
                  className={clsx({
                    [!props.isMobile ? classes.magno_grid_row : classes.magno_grid_row_mobile]: !showMenu, 
                    [classes.magno_grid_row_selected]: select === idx, 
                    [classes.magno_grid_row_menu]: showMenu && select === idx, 
                  })}                  
                >
                  <div 
                    id={"row_"+row["id"]} 
                    key={idx} 
                    className={!props.isMobile ? classes.magno_grid_row_col : classes.magno_grid_row_col_mobile} 
                    onMouseOver={!props.disableHover ? () => setHoverIndex(idx) : undefined}
                  >
                    {
                      props.columns.map((value: GridColDef<GridValidRowModel>, index: number, array: GridColDef<GridValidRowModel>[]): any =>
                      (
                        <div 
                          key={index} 
                          id={"cell_"+index+"_"+row["id"]+"_"+idx+"_"+props.objectId}
                          onClick={value.type === "htext" ? handleRowClick : undefined}

                          className={clsx({
                            [classes.row_cell_highlight]: hoverIndex !== -1 && idx === hoverIndex && !showMenu,
                            [!props.isMobile ? classes.row_cell_odd : classes.row_cell_odd_mobile]: idx % 2 === 0,
                            [classes.row_cell_even]: idx % 2 !== 0,
                            [classes.col_menu]: showMenu && select === idx, 
                          })}

                          style={{
                            //TRANSITION
                            maxHeight: (showMenu && idx === select) ? "200px" : "70px", 
                            transition: (showMenu && idx === select) ? "max-height 0.3s ease-in" :"max-height 0.3s ease-out",
                            height: (showMenu && idx === select) ? "100px" :"38px",  
                            fontFamily: "sans-serif",
                            fontSize:  !props.isMobile ? "18px" : "12px",
                            //width: (index === (props.columns.length-1) ? (value.width! - 17)+"px" : value.width+"px"),
                            width: (value.width+"px"),
                            lineHeight: !props.isMobile ? "38px" : "28px",
                            color: (
                              value.type === "sum" || (value.type === "number")
                                ? "rgb(12, 129, 166)"
                                : (value.type !== "number" && value.type !== "number2" && value.type !== "number3"  )
                                  ? "rgb(12, 129, 166)" 
                                  : "rgb(12, 129, 166)"),        

                            textAlign: (
                              value.type === "sum" || (value.type === "number")
                                ? "center"
                                : (value.type !== "number" && value.type !== "number2" && value.type !== "number3"  )
                                  ? "left" 
                                  : "right"),  
                            paddingRight: ((value.type === "number" || value.type === "number2" || value.type === "number3") ? "3px" : "0px"), 
                            //color: (row["level"]!== undefined && row["level"] === 1 ? "blue" : "black"),
                          }}
                          >
                          {
                            value.renderCell !== undefined ? 
                              <div className={!props.isMobile ? classes.icon : classes.icon_mobile}><Status  status={row[value.field]} width={value.width}/></div>
                              :<span>
                                  {
                                  value.type === "date_aws" 
                                    ? GetAWS_Date(row[value.field]) 
                                    : value.type === "date" 
                                      ? GetDate(row[value.field])                                     
                                      : value.type === "status" 
                                        ? GetHeaderStatus(row[value.field]) 
                                        : value.type === "text"
                                          ? row[value.field]
                                            : value.type === "htext"
                                            ? <div className={classes.pointer}>{row[value.field]}</div>
                                              : value.type === "number"
                                                ? Number(row[value.field]).toFixed(0)
                                                  : value.type === "number2"
                                                  ? Number(row[value.field]).toFixed(2)          
                                                    : value.type === "number3"
                                                    ? Number(row[value.field]).toFixed(3)                                                                                 
                                                      : value.type === "sum"
                                                      ? row[value.field].length
                                                        : value.type === "editicon"
                                                          ?<EditIcon sx={{ color: row["can_edit"] ? "#A266B9" : "silver"}} id={"edit_"+idx+"_"+row["id"]} onClick={row["can_edit"] ? handleIconClick : undefined}/>

                                                            : value.type === "lock" && row[value.field] === "locked"
                                                            ?<LockIcon color={"error"} style={{display: "inline-flex", marginLeft: "15px", marginTop: !props.isMobile ? "7px" : "0px", cursor: "pointer"}} id={"lock_"+idx+"_"+index+"_"+row["id"]} onClick={handleIconClick}/>

                                                              : value.type === "lock" && row[value.field] === "unlocked"
                                                              ?<LockOpenIcon color={"success"} style={{display: "inline-flex", marginLeft: "15px", marginTop: !props.isMobile ? "7px" : "0px", cursor: "pointer"}} id={"lock_"+idx+"_"+index+"_"+row["id"]} onClick={handleIconClick}/>

                                                                : value.type === "ship" 
                                                                ?<LocalShippingIcon color={"success"} style={{display: "inline-flex", marginLeft: "15px", marginTop: "8px", cursor: "pointer"}} id={"lock_"+idx+"_"+index} onClick={handleIconClick}/>

                                                                  : value.type === "deleteicon"
                                                                  ?<DeleteIcon sx={{ color: row["can_delete"] ? "#A266B9" : "silver" }} id={"delete_"+idx+"_"+row["id"]} onClick={row["can_delete"] ? handleIconClick : undefined}/>

                                                                    : value.type === "menuicon"
                                                                    ?(showMenu && select === idx 
                                                                      ? <ExpandMoreIcon id={"menu_"+idx+"_"+idx+"_"+row["id"]} onClick={handleIconClick} sx={{position: "relative", left: "0px"}}/> 
                                                                      : <ExpandLessIcon id={"menu_"+idx+"_"+idx+"_"+row["id"]} onClick={handleIconClick} sx={{position: "relative", left: "0px"}}/>)
                                                                    :<></>
                                  }
                                </span>
                            }

                        </div>
                                
                      ))
                    }      
                  </div>      
                </div>    
              ))
        }     
      </div>
    </div>
  );
};
export default MagnoGrid;
