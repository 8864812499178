import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import t_shirt from '../images/t-shirts.png';
import t_shirt_small from '../images/t-shirts-small.png';
import Button from '@material-ui/core/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
    Typography,
  } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "../css/Merch.css";
import {
    updateMerchOrderThunk,
    selectCredentials,
    selectMerchandise,
} from "../redux/JamHawSlice";

import {
    IMerchandise,
    IMerchProps,
} from '../redux/JamHawInterface';

import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {IsMobile} from "./MainLibrary";

const useStyles = makeStyles((theme) => ({
    shirts: {
  
      height: "55%",
    },
    shirts_mobile: {
      height: "200px",
    },
    combo_input: {
      backgroundColor: ""
    },
    send_btn: {
        marginTop:"30px",
        width: "200px",
    },
    header_panel: {
      //display: "inline-flex",
      width: "100%",
    },    
    merch_text:{
      textAlign: "center",
    },
  }))

export const Merchandise = (_merch:IMerchProps) => {

    const dispatch  = useDispatch();
    const classes = useStyles();
    const credentials = useSelector(selectCredentials);
    const merchandise = useSelector(selectMerchandise);
    const [small, SetSmall] = React.useState('s_0');
    const [medium, SetMedium] = React.useState('m_0');
    const [large, SetLarge] = React.useState('l_0');
    const [xlarge, SetXLarge] = React.useState('x_0');
   
    useEffect(() => {
        // console.log("merchandise:", merchandise);
        let merch = merchandise.filter(function(mrch:IMerchandise){
            return mrch.email === credentials.userName
        });

        if(merch.length === 1)
        {
            console.log("MERCH:", merch[0]);
            SetSmall("s_"+merch[0].small);
            SetMedium("m_"+merch[0].medium);
            SetLarge("l_"+merch[0].large);
            SetXLarge("x_"+merch[0].xlarge);        
        }      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    const handleSizeChange = (event:any) => {
        //console.log("handleSizeChange", event.target.value);
        
        let shirtType = event.target.value.split('_')[0];
        let shirt = Number(event.target.value.split('_')[1]);
        
        //console.log("[",shirtType,"][", shirt,"][", event.target.value,"]");
    
        switch(shirtType){
          case 's': SetSmall(event.target.value); break;
          case 'm': SetMedium(event.target.value); break;
          case 'l': SetLarge(event.target.value); break;
          case 'x': SetXLarge(event.target.value); break;
        }
      }
    
      const handleRequest= (event:any) => {
        //console.log("handleRequest", event.currentTarget.id);
        if(credentials.userName.length === 0)
        {
            toast.error("You must RSVP before you can make a request");
            return;
        }
        let payLoad:IMerchandise = {
            "email"  : credentials.userName,
            "small"  : (small !== "None")  ? Number(small.split('_')[1]) : 0,
            "medium" : (medium !== "None") ? Number(medium.split('_')[1]) : 0,
            "large"  : (large !== "None")  ? Number(large.split('_')[1]) : 0,
            "xlarge" : (xlarge !== "None") ? Number(xlarge.split('_')[1]) : 0,
        }
        //console.log("handleRequest", payLoad);
        dispatch(updateMerchOrderThunk(payLoad));
        _merch.handleMerchRequest();
      }

      let shirtImage = (<img className={classes.shirts} src={t_shirt} alt="t-shirt"/>);
      if(IsMobile())
      {
        shirtImage = (<img className={classes.shirts} src={t_shirt_small} alt="t-shirt-small"/>);
      }

    return (
      <div className="sub-canvas">
        <Box
          sx={{
              justifyContent:"center",
              alignItems:"center",          
              display: 'flex',
              padding: '5px',
              breakInside: 'auto',
              flexWrap: 'wrap',
              '& > :not(style)': {
              m: 3,
              // width: 800,
              // height: 630,
              },
              
          }}
          >
            <Paper elevation={4}
                style={{
                    padding: 10,
                    border: "1px solid black",
                    overflow: 'auto'
                }}
            >

              <div className={"merch_canvas"}>
                <div className={classes.header_panel}>
                  <Typography  
                      style={{
                        fontWeight: 800,
                      }}
                      variant="h6">
                        Merchandise
                  </Typography>            
                  <CloseIcon  
                    onClick={_merch.handleClose}                               
                    style={{
                        //marginTop: '15px',
                        //marginBottom: '10px',
                        marginLeft: 'calc(100% - 165px)',
                        fontWeight: 'bold',
                    }}/>         
                  </div>         
                  <div className={classes.merch_text}>    
                      <div className="t-shirt">{shirtImage}</div>
                  
                      <div>
                        <div id="combos">
                            <FormControl sx={{ m: 1, width: 120 }}>
                                      <InputLabel id="small-helper-label">Small</InputLabel>
                                      <Select
                                        labelId="small-label"
                                        id="small-size"
                                        value={small}
                                        label="Size"
                                        onChange={handleSizeChange}
                                      >
                                        <MenuItem value={'s_0'}>None</MenuItem>
                                        <MenuItem value={'s_1'}>1</MenuItem>
                                        <MenuItem value={'s_2'}>2</MenuItem>
                                        <MenuItem value={'s_3'}>3</MenuItem>
                                        <MenuItem value={'s_4'}>4</MenuItem>
                                      </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 120 }}>
                                      <InputLabel id="medium-helper-label">medium</InputLabel>
                                      <Select
                                        labelId="medium-label"
                                        id="medium-size"
                                        value={medium}
                                        label="Size"
                                        onChange={handleSizeChange}
                                      >
                                        <MenuItem value={'m_0'}>None</MenuItem>
                                        <MenuItem value={'m_1'}>1</MenuItem>
                                        <MenuItem value={'m_2'}>2</MenuItem>
                                        <MenuItem value={'m_3'}>3</MenuItem>
                                        <MenuItem value={'m_4'}>4</MenuItem>
                                      </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 120 }}>          
                                      <InputLabel id="large-helper-label">large</InputLabel>
                                      <Select
                                        labelId="large-label"
                                        id="large-size"
                                        value={large}
                                        label="Size"
                                        onChange={handleSizeChange}
                                      >
                                        <MenuItem value={'l_0'}>None</MenuItem>
                                        <MenuItem value={'l_1'}>1</MenuItem>
                                        <MenuItem value={'l_2'}>2</MenuItem>
                                        <MenuItem value={'l_3'}>3</MenuItem>
                                        <MenuItem value={'l_4'}>4</MenuItem>
                                      </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 120 }}>                
                                      <InputLabel id="xlarge-helper-label">Xlarge</InputLabel>
                                      <Select
                                        labelId="xlarge-label"
                                        id="xlarge-size"
                                        value={xlarge}
                                        label="Size"
                                        onChange={handleSizeChange}
                                      >
                                        <MenuItem value={'x_0'}>None</MenuItem>
                                        <MenuItem value={'x_1'}>1</MenuItem>
                                        <MenuItem value={'x_2'}>2</MenuItem>
                                        <MenuItem value={'x_3'}>3</MenuItem>
                                        <MenuItem value={'x_4'}>4</MenuItem>
                                      </Select>
                            </FormControl>
                        </div>
                        <Button 
                            className={classes.send_btn}
                            variant="contained" 
                            id="request-btn" 
                            onClick={handleRequest}>Send Request</Button>
                      </div>

                  </div>
              </div>

            </Paper>
          </Box>
        </div>
    );
    }