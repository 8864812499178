import Dialog from "@material-ui/core/Dialog";
import {IDisable} from './MainContainer';
import {
  Typography,
} from "@material-ui/core";

export default function DisableView(callback:IDisable): JSX.Element {
  
  return (
    <div onClick={callback.fnBack}>
      <Dialog
        PaperProps={{
          style: {
            zIndex: 99999,
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        open={true}
      >
      </Dialog>
    </div>
  );
}