import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

import {
    IGDPR,
} from '../redux/JamHawInterface';

import "../css/GDPR.css";
import {IsMobile} from "./MainLibrary";

const useStyles = makeStyles((theme) => ({
    header: {

    },
    header_panel: {
        display: "inline-flex",
        width: "100%",
    },
    description:{

    },
    neccessary: {
        paddingTop: "5px",
        width: "100%",
        display: "inline-flex",
        backgroundColor: "#f2f2f2",
        border: "1px solid: #f2f2f2",
        height: "40px",
        borderRadius: "9px",
        fontSize: "14px",
        marginTop: "10px",
    },
    neccessary_panel: {
        display: "inline-flex",
    },
    open_chev : {
        position: "relative",
        top: "-3px",
        //-mstransform: "rotate(90deg)", /* IE 9 */
        //-WebKitTransform: "rotate(90deg)", /* Safari prior 9.0 */
        transform: "rotate(90deg)", /* Standard syntax */
    },    
}));

const SvgChevron = (props:any): JSX.Element => {
    return (<div className="icon-chevron">
        <svg id={"chev-svg"} className="icon-chevron" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 450 450" aria-labelledby="title">
        <title id="title">{""}</title>
        <path fill='currentColor' d="M176 77l198 198-198 198" strokeWidth="1"/>
        </svg></div>
        );
}

export const GDPR = (_gdpr:IGDPR): JSX.Element => {
  const classes = useStyles();
  const [neccessary_checked, setChecked] = React.useState(false);
  
  const handleChange = () => {
    setChecked(!neccessary_checked);
  };  

  const handleClose = (evt:any) => {
        _gdpr.callback_close(evt);
  };  


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  let on_show_styles = {maxHeight: "200px", transition: "max-height 0.5s ease-in", overflow: "hidden", fontSize: "12px" };
  let on_hide_styles = {maxHeight: "0px", transition: "max-height 0.5s ease-out", overflow: "hidden", fontSize: "12px"};
  
  const label = { inputProps: { 'aria-label': 'Switch disabled' } };

  return (
    <div className="sub-canvas">
            {
                _gdpr.open_modal &&
                    <Dialog
                        PaperProps={{
                            style: {
                                zIndex: 99999,
                                backgroundColor: "transparent",
                                boxShadow: "none",
                            },
                        }}
                        open={true}
                    >
                        <Box
                            sx={{
                                justifyContent:"center",
                                alignItems:"center",          
                                display: 'flex',
                                padding: '5px',
                                breakInside: 'auto',
                                flexWrap: 'wrap',
                            
                                '& > :not(style)': {
                                m: 3,
                                maxWidth: 700,
                                //height: IsMobile() ? 500 : 400,
                                },
                            }}
                            >
                                <Paper elevation={4}
                                    style={{
                                        borderRadius: '15px',
                                        textAlign: 'left',
                                        padding: 10,
                                        border: "1px solid white",
                                        overflow: 'auto',
                                        scrollbarWidth: "none",
                                        width: "100%",
                                        minWidth: 280
                                    }}
                                >
                                    <div className={classes.header_panel}>
                                        <Typography                             
                                            style={{
                                                width: '250px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                                marginLeft: '10px',
                                                fontWeight: 'bold',
                                            }}
                                            component="h1" variant="h5">Privacy Overview
                                        </Typography>
                                        <CloseIcon  
                                            onClick={handleClose}                               
                                            style={{
                                                marginTop: '15px',
                                                marginBottom: '10px',
                                                marginLeft: 'calc(100% - 300px)',
                                                fontWeight: 'bold',
                                            }}/>
                                    </div>

                                    <Divider variant="middle" />

                                    <Typography                          
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                            marginLeft: '5px',
                                        }}>
                                        This website uses cookies to improve your experience while you navigate through the website. 
                                        Out of these cookies, the cookies that are categorized as necessary are stored on your browser 
                                        as they are essential for the working of basic functionalities of the website. 
                                        We also use third-party cookies that help us analyze and understand how you use this website. 
                                        These cookies will be stored in your browser only with your consent. 
                                        You also have the option to opt-out of these cookies. 
                                        But opting out of some of these cookies may have an effect on your browsing experience.
                                    </Typography>
                                    
                
                                    <div className={classes.neccessary} onClick={handleChange}>
                                        <SvgChevron/>
                                        <Typography                         
                                            variant="h6"
                                            style={{
                                                marginLeft: '9px',
                                            }}                                
                                        >Neccessary</Typography> 
                                        <Typography
                                        style={{
                                            marginTop: '5px',
                                            marginLeft: 'calc(100% - 275px)',
                                        }}>                            
                                            Always Enabled
                                        </Typography>    
                                    </div>
                                    <Typography  
                                        style={ 
                                            neccessary_checked ? on_show_styles :  on_hide_styles
                                        }>
                                        These cookies essential for the website to function properly.
                                        Only includes cookies that ensures basic functionalities and security
                                        features of the website. These cookies do not store any personal information except 
                                        your email if you choose to register. All passwords are MD5 encrypted.
                                    </Typography>      
                                
                                </Paper>
                        </Box>
                </Dialog>
            }
    </div>
  );
};

export default GDPR;
