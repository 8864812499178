/**
 * A MAterial UI appbar thats locked to the top of the screen.
 */
import React from "react";
import {
  IconButton,
  AppBar,
  Typography,
  Toolbar,
  Fab,
  Fade,
  Menu,
  InputBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HideOnScroll from "./HideOnScroll";
//Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

//Styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  // necessary for content to be below app bar
  toolbarspacer: theme.mixins.toolbar,
  topBar: {
    top: 0,
    bottom: "auto",
  },
  bottomBar: {
    top: "auto",
    bottom: 0,
  },
  title: {
    flexGrow: 0,
  },
  grow: {
    //grow is used to take up the space on the app bar between the title and the buttons on the right.
    flexGrow: 1,
  },
  shrink: {
    //shrink is used to take away the space on the app bar between the title and the buttons on the right.
    flexGrow: 0,
  },
  fabButtonBottom: {
    //for the button in the middle of the appbar
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  fabButtonTop: {
    //for the button in the middle of the appbar
    position: "absolute",
    zIndex: 1,
    top: 30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  panelMenu: {
    marginRight: "20px",
  },  
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

//We export our props interface so other people can send us the correctly typed and shaped props.
/**
 * @description the props for the FPAppbar.
 * @property isBottomBar: boolean; //Enables the top/bottom bar
 * @property isHideOnScroll: boolean; //set to true to hide this app bar when we scroll.
 * @property onBackClick?: (event: any) => void; //If you provide a callback then a back arrow will be shown on the left and the callback assigned to that button.
 * @property title?: string; //the title to show on the app bar
 * @property onSearchClick?: (text: string) => void; //if you provide a callback then a search will be shown on the right hand side. clicking this icon will show a search overlay. If the user then presses the glass icon or the enter key then the text entered will be passed to your callback.
 * @property searchIconElement?: JSX.Element  //if supplied will be used instead of the SearchIcon. Should be a Icon from Material U.
 * @property leftMenuItems?: JSX.Element[]; //will show a standard menu icon on the right if this element is not undefined. note all elements must have a unique id or the menu will not close
 * @property rightMenuItems?: JSX.Element[]; //will show a morevert icon on the far left if this is not undefined.
 * @property fabIconElement?: JSX.Element; //will show a fab icon containing whatever you supply here
 * @property onFabIconClick?: (event: any) => void; //will be called back when the fab icon is clicked. Note you must still provide a fabIconElement to see the fab icon
 * @property children?: JSX.Element[]; //children that will be rendered between the search icon and the morevery right hand menu note: you must set color="inherit" to pickup  the right colours for your components, or color="secondary" e.g. for a switch
 * @property onSearchClosed?: (event: any) => void; //Optional event that can be called when the search field is closed.
 * @property isNumericSearch?: boolean; //Optionally sets the input mode from text to numeric if true.
 * @property onPanelClick?: (event: any) => void; //will be called back when the menu icon is clicked. 
 */
export interface IFPAppbarProps {
  isBottomBar: boolean;
  isHideOnScroll: boolean;
  onBackClick?: (event: any) => void;
  title?: string;
  onPanelClick?:  (event: any) => void;
  panelIconElement?: JSX.Element;
  onSearchClick?: (text: string) => void;
  searchIconElement?: JSX.Element;
  onSearchClosed?: () => void;
  leftMenuItems?: JSX.Element[];
  rightMenuItems?: JSX.Element[];
  fabIconElement?: JSX.Element;
  onFabIconClick?: (event: any) => void;
  children?: JSX.Element[]; //This is required to stop error message when trying to call props.children within the FPAppbar scope (go figure)
  isNumericSearch?: boolean;
}

const action_list = {} as any; //Init hash table
const menu_array = [] as any; //Array

/**
 * FPAppbar
 * @param props : IFPAppbarProps
 * @returns  JSX.Element
 */
const FPAppbar = (
  props: IFPAppbarProps = {
    //define the default props here so people can do <FPAppbar /> and just get a default bar that works.
    isBottomBar: false,
    isHideOnScroll: true,
  }
): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchShown, setSearchShown] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>("");
  const [isNavClicked, setIsNavClicked] = React.useState<boolean>(false);

  //Grab our styles at the start once so its always done in the same order.
  const classes = useStyles();

  //Adds an onclick event listener to all the items in the menu
  //this will close the menu when you click on those items
  const CheckAndSetForClickListener = () => {
    menu_array.forEach(function (idd: any, i: number) {
      const element = document.getElementById(idd);
      if (element !== null) {
        if (!action_list[idd].hasClick) {
          action_list[idd].hasClick = true;
          element.addEventListener("click", subMenuItemClicked);
        }
      }
    });
  };

  //Clicked on the right hand menu
  const subMenuClicked = (event: any) => {
    setIsNavClicked(true); //true = right
    setAnchorEl(event.currentTarget);
    setTimeout(function () {
      CheckAndSetForClickListener();
    }, 100);
  };

  //clicked on the left hand more vert menu
  const subMoreMenuClicked = (event: any) => {
    setIsNavClicked(false); //false = left
    setAnchorEl(event.currentTarget);
    setTimeout(function () {
      CheckAndSetForClickListener();
    }, 100);
  };

  const subMenuItemClicked = () => {
    setAnchorEl(null);
  };

  //Close the menu by setting the archor element to null so it does not render
  const menuOnClose = () => {
    setAnchorEl(null);
  };

  //show the search toolbar
  const onInternalSearchClick = (event: any) => {
    setSearchText(""); //clear the entered search text.
    setSearchShown(true); //Need to do this to force re-render
  };

  //fires when the user presses the enter key or clicks on the glass icon to fire a search
  //hides the search bar (thats actually not what materialui.io says but thats what we like)
  const onDoSearch = () => {
    setSearchShown(false);
    if (props.onSearchClick) {
      //get the search text entered and pass it to the callback
      props.onSearchClick(searchText);
    }
  };

  //Cancel the search, clear the text, hide the search bar. Escape or the X icon does this
  const onCancelSearch = (event: any) => {
    setSearchText(""); //clear the entered search text.
    setSearchShown(false); //just hide the search
    if(props.onSearchClosed)
    {
      props.onSearchClosed();
    }
  };

  //Draws another AppBar over the top as a search bar.
  const renderSearchBar = (): JSX.Element => {
    return (
      <Fade in={searchShown}>
        <AppBar
          id={props.isBottomBar ? "appbar-bottom" : "appbar-top"}
          className={props.isBottomBar ? classes.bottomBar : classes.topBar}
          color="secondary"
        >
          <Toolbar>
            <IconButton
              edge="start"
              aria-label="cancel"
              onClick={onCancelSearch}
              color="inherit"
              id={props.isBottomBar ? "close-button-bottom" : "close-button-top"}
            >
              <CloseIcon id={props.isBottomBar ? "close-icon-bottom" : "close-icon-top"}/>
            </IconButton>
            <InputBase
              id={props.isBottomBar ? "input-bottom" : "input-top"}
              autoFocus={true}
              autoComplete={"off"}
              type={props.isNumericSearch ? "number" : "text"}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              //onBlur={leaveInputBox}
              onKeyUp={(event) => {
                if (event.keyCode === 13 || event.key === "Enter") {
                  //End our search
                  onDoSearch();
                } else if (event.key === "Escape") {
                  onCancelSearch({});
                }
              }}
              onChange={(event) => {
                //adding the onChange event
                setSearchText(event.target.value);
              }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
              value={searchText}
            />
            <IconButton
              edge="end"
              aria-label="search"
              onClick={onDoSearch}
              color="inherit"
              id={props.isBottomBar ? "search-button-bottom" : "search-button-top"}
            >
              {props.searchIconElement ? (
                props.searchIconElement
              ) : (
                <SearchIcon />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
      </Fade>
    );
  };

  // SUBMENU SELECTION CLICK /////////////////////////////////////////////////////////
  let menu_items:any;

  //pick our left or right menu items
  if (isNavClicked && props.leftMenuItems) {
    menu_items = props.leftMenuItems; //TL-BL
  } else if (props.rightMenuItems) {
    menu_items = props.rightMenuItems; //TR-BR
  }

  //If we have menu items then add them to the menu_array so we will add the
  //closemenu to the onclick if the menu is shown.
  if (menu_items) {
    //Get list of unique nav menuitem id's
    menu_items.forEach(function (itemx: any, ix: number) {
      var idd = itemx.props.id;
      if (action_list[idd] === undefined) {
        action_list[idd] = { id: idd, _prop: itemx.props, hasClick: false };
        menu_array.push(idd);
      }
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <HideOnScroll
        isEnabled={props.isHideOnScroll}
        direction={props.isBottomBar ? "up" : "down"}
      >
        <AppBar
          className={props.isBottomBar ? classes.bottomBar : classes.topBar}
        >
          <Toolbar>
            {/* LEFT ZONE */}

            {/* BACK CLICK */}
            {props.onBackClick && !searchShown ? (
              <IconButton
                edge="start"
                onClick={props.onBackClick}
                className={"back-icon"}
                color="inherit"
                id={props.isBottomBar ? "back-button-bottom" : "back-button-top"}
              >
                <ArrowBackIcon/>
              </IconButton>
            ) : null}

            {/* NAVIGATION MENU CLICK */}
            {props.leftMenuItems && !searchShown ? (
              <IconButton id={props.isBottomBar ? "menu-button-bottom-left" : "menu-button-top-left"} edge="start" onClick={subMenuClicked} color="inherit">
                <MenuIcon />
              </IconButton>
            ) : null}

            {/* SIDE PANEL */}
            {
              props.panelIconElement && 
                <IconButton
                  edge="end"
                  onClick={props.onPanelClick}
                  color="inherit"
                  className={classes.panelMenu} 
                  id={props.isBottomBar ? "panel-button-bottom" : "panel-button-top"}
                >              
                   <Typography id={props.isBottomBar ? "logo-bottom" : "logo-top"}>{props.panelIconElement}</Typography>
                </IconButton>
            }

            {
              /* TITLE */
              props.title && props.title.length > 0 && !searchShown ? (
                <Typography id={props.isBottomBar ? "title-bottom" : "title-top"}>{props.title}</Typography>
              ) : null
            }

            {/* MENU */}
            <Menu
              id={props.isBottomBar ? "menu-for-btm" : "menu-for-top"}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={menuOnClose}
              TransitionComponent={Fade}
            >
              {menu_items}
            </Menu>

            {!searchShown ? (
              <div className={classes.grow} />
            ) : (
              <div className={classes.shrink} />
            )}

            {/* RIGHT SIDE */}

            {/* APPBAR EXPANDING SPLITTER DO NOT EVER REMOVE*/}

            {!searchShown ? (
              <Typography className={classes.title}></Typography>
            ) : null}

            {
              /* RIGHT ACTION ICON BUTTONS */
              !searchShown && props.children ? props.children : null
            }

            {
              /* SHOW THE SEARCH OPEN BUTTON */
              props.onSearchClick && !searchShown ? (
                <IconButton
                  edge="end"
                  onClick={onInternalSearchClick}
                  color="inherit"
                  id={props.isBottomBar ? "search-button-bottom" : "search-button-top"}
                >
                  {props.searchIconElement ? (
                    props.searchIconElement
                  ) : (
                    <SearchIcon />
                  )}
                </IconButton>
              ) : null
            }

            {
              /* VERTICAL MORE ICON BUTTONS */
              props.rightMenuItems && !searchShown ? (
                <IconButton
                  edge="end"
                  onClick={subMoreMenuClicked}
                  color="inherit"
                  id={props.isBottomBar ? "more-button-bottom" : "more-button-top"}
                >
                  <MoreVertIcon/>
                </IconButton>
              ) : null
            }

            {
              /* FAB */
              props.fabIconElement === undefined || searchShown ? (
                <></>
              ) : (
                <Fab
                  id={props.isBottomBar ? "fab-button-bottom" : "fab-button-top"}
                  color="secondary"
                  aria-label="add"
                  className={
                    props.isBottomBar
                      ? classes.fabButtonBottom
                      : classes.fabButtonTop
                  }
                  onClick={props.onFabIconClick}
                >
                  {props.fabIconElement}
                </Fab>
              )
            }
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      {/* SEARCH BOX  just render another appbar over the top of this one, simples*/}
      {searchShown ? renderSearchBar() : null}

      {/** Used to space out under the app bar
       * If we have a fabIcon then we need to double space to make sure we dont block access to the content.
       */}
      {props.fabIconElement !== undefined && props.isBottomBar ? (
        <div className={classes.toolbarspacer} />
      ) : null}
      <div className={classes.toolbarspacer} />
    </div>
  );
};
export default FPAppbar;
