/**
 * ENUMS for the thunk response states
 */
export enum ELoadingStatus {
  "idle",
  "loading",
  "loaded",
  "error",
}

/**
 * SYSTEM
 */
export interface ISystem {
  system_name: string;
}

/**
 * APPLICATION
 * These are the properties returned from the CORE_GLOBAL_GetAppVersion SP
 */
export interface IApplication {
  ApplicationID: number;
  current_version: string;
  min_version: string;
  Name: string;
}

/**
 * ROLE ARGUMENT
 * This is the argument passed to the getRoleUsersThunk SP
 */
export interface IRoleArgs {
  role?: string;
}

/**
 * ROLE SUPERVISOR
 * Used for aguments for getRoleSupervisorsThunk
 */
export interface IRoleSupervisor {
  userid: string;
  UserName: string;
}

/**
 * ROLE USER
 * argument passed down to ILoginViewProps
 */
export interface IRoleUser {
  UserId: string;
  UserName: string;
  PassWord: string;
}

/**
 * VALIDATE
 * The base64 credential encoded string passed to getValidatesThunk
 */
export interface IValidate {
  confirmed: string;
}

export interface IValidateArgs {
  user: string;
  password: string;
}

/**
 * S L I C E  I N T E R F A C E
 */
export interface ILoginState {
  //SYSTEM
  system_loading: ELoadingStatus;
  system_type: string;
  system_error_msg: string;
  systems?: ISystem[];

  //APPLICATION
  application_loading: ELoadingStatus;
  application_error_msg: string;
  /**
   * An array of IApplication. Should only be one record
   */
  applications?: IApplication[];
  current_version: string;
  min_version: string;

  //ROLE SUPERVISOR
  role_supervisor_loading: ELoadingStatus;
  role_supervisor_error_msg: string;
  /**
   * Returns an array of supervisory roles
   */
  role_supervisors?: IRoleSupervisor[];

  //ROLE USER
  role_user_loading: ELoadingStatus;
  role_user_error_msg: string;
  /**
   * Returns an array of role users
   */
  role_users?: IRoleUser[];

  //USER
  user_loading: ELoadingStatus;
  user_error_msg: string;

  //VALIDATE
  validate_loading: ELoadingStatus;
  validate_error_msg: string;
  confirmed?: string;
}
