import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoginResponse } from "../Framework/Login/Login";
import { ViewTypes } from "../components/views";
import { RootState } from "../store";
import { IFPAppbarProps } from "../Framework/FPAppBar/FPAppbar";

export interface IMessage {
  msg_id: string;
  app_id: string;
  message: string;
}

export interface ISubMenu {
  zone: string;
  title: string;
  enabled: boolean;
  handler?: JSX.Element;
}

/***
 * Pass TR barcode to SpotCheckLoadTrolleyThunk
 */
export interface ISubMenuArg {
  appbar: IFPAppbarProps;
  exclude_view: string;
}

export interface ISessionState {
  credentials_ready: boolean;
  notification_count: number;
  isLoggedIn: boolean;
  isOK: boolean;
  isCANCEL: boolean;
  currentDate: string;
  currentManualBarcode: string;
  errorMsg: string;
  checklist_error_msg: string;
  password: string;
  showManualBarcodeEntry: boolean;
  showDialog: boolean;
  statusText: string;
  userName: string;
  isSupervisor: boolean;
  passed: boolean;
  view: string;
  last_view: string;
  subMenu: ISubMenu[];
  showWait: boolean;
  check_results: string;
}

const initialState: ISessionState = {
  credentials_ready: false,
  isLoggedIn: false,
  isOK: false,
  isCANCEL: false,
  currentDate: "",
  currentManualBarcode: "",
  errorMsg: "",
  checklist_error_msg: "",
  notification_count: 0,
  showManualBarcodeEntry: false,
  showDialog: false,
  passed: false,
  statusText: "",
  password: "",
  userName: "",
  check_results: "",
  isSupervisor: false,
  view: "SCANNING_VIEW",
  last_view: "",
  subMenu: [
    { zone: ViewTypes.INFO_VIEW, title: "INFO", enabled: true },
    { zone: "LOGOUT", title: "Logout", enabled: true },
  ],
  showWait: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    /**
     * Set current date from datepicker
     */
    UpdateDate: (state, action: PayloadAction<string>) => {
      state.currentDate = action.payload;
      //console.log("UpdateDate", state.currentDate);
    },
    /**
     * Sets the screen view
     */
    UpdateView: (state, action: PayloadAction<string>) => {
      state.last_view = state.view;
      state.view = action.payload;
    },
    /**
     * Shows the wait cursor
     */
    UpdateShowWait: (state, action: PayloadAction<boolean>) => {
      state.showWait = action.payload;
    },
    /**
     * Sets the user credentials
     */
    UpdateCredentials: (state, action: PayloadAction<ILoginResponse>) => {
      state.userName = action.payload.user;
      state.password = action.payload.password;
      state.isSupervisor = action.payload.isSupervisor;

      //console.log("UpdateCredentials", action.payload.isSupervisor);
      state.credentials_ready = true;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  UpdateView,
  UpdateDate,
  UpdateCredentials,
  UpdateShowWait,
} = sessionSlice.actions;

/**
 * View Selected
 */
export const selectView = (rootState: RootState) => {
  return rootState.session.view;
};

/**
 * Retain the last view selected so we can go back to previous screen
 */
export const selectLastView = (rootState: RootState) => {
  return rootState.session.last_view;
};

/**
 * Gets the submenu component
 */
export const selectSubMenu = (rootState: RootState) => {
  return rootState.session.subMenu;
};

/**
 * set the current date
 */
export const selectCurrentDate = (rootState: RootState) => {
  return rootState.session.currentDate;
};

/**
 * Gets the current credentials
 */
export const selectCredentials = (rootState: RootState) => {
  return {
    user: rootState.session.userName,
    password: rootState.session.password,
    isSupervisor: rootState.session.isSupervisor,
  };
};

/**
 * Retains the pass status for the save operation
 */
export const selectHasPassed = (rootState: RootState) => {
  return rootState.session.passed;
};

/**
 * Concatenated string of check results
 */
export const selectCheckResults = (rootState: RootState) => {
  return rootState.session.check_results;
};

/**
 * Confirms that the user has completed login
 */
export const selectCredentialsReady = (rootState: RootState) => {
  return rootState.session.credentials_ready;
};

export default sessionSlice.reducer;
