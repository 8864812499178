

export function GalleryCROWS_NEST(){
  return {
    "images": [
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_001.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_002.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_003.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_004.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_005.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_006.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_007.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_008.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_009.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_010.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_011.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_012.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryCROWS_NEST/IMG_013.jpg"}
    ]
  }
}
