import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../Framework/hooks";
import LoginView from "./LoginView";
import { ILoginProps } from "./Login";
import {
  getApplicationsThunk,
  selectApplications,
  selectApplicationError,
  selectCurVer,
  selectMinVer,
  getRoleSupervisorsThunk,
  selectRoleSupervisors,
  selectRoleSupervisorsError,
  getSystemsThunk,
  selectSystems,
  selectSystemError,
  getRoleUsersThunk,
  selectRoleUsers,
  selectRoleError,
  selectApplicationLoading,
  selectRoleUserLoading,
  selectRoleSupervisorLoading,
  selectSystemLoading,
  getValidatesThunk,
  selectValidate,
  selectValidateError,
} from "./LoginSlice";

import { ELoadingStatus, IRoleSupervisor } from "./LoginInterface";

import { makeStyles } from "@material-ui/core/styles";
import "./css/Login.css";
import StageNowDlg from "./StageNowDlg";
import { Button } from "@material-ui/core";
import error_triangle from "./images/network_error.png";
import loading_gif from "./images/loading.gif";

const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "120px",
    color: "primary",
    marginLeft: "auto",
    marginTop: "100%",
  },
}));

export interface ILoginContainerProps {
  handleOk: () => void;
}

var internal_user = "";
var internal_password = "";
var fail_description = "";

export function LoginContainer(_lp: ILoginProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const applications = useSelector(selectApplications);
  const role_users = useSelector(selectRoleUsers);
  const role_supervisors = useSelector(selectRoleSupervisors);
  const validate = useSelector(selectValidate);
  const systems = useSelector(selectSystems);
  const curVer = useSelector(selectCurVer);
  const minVer = useSelector(selectMinVer);
  const roleUserLoading = useSelector(selectRoleUserLoading);
  const applicationLoading = useSelector(selectApplicationLoading);
  //const validateLoading = useSelector(selectValidateLoading);
  const roleSupervisorLoading = useSelector(selectRoleSupervisorLoading);
  const systemLoading = useSelector(selectSystemLoading);

  //Network Errors
  const appError = useSelector(selectApplicationError);
  const sysError = useSelector(selectSystemError);
  const rolError = useSelector(selectRoleError);
  const rlsError = useSelector(selectRoleSupervisorsError);
  const valError = useSelector(selectValidateError);
  const [isUsersLoaded, setIsUsersLoaded] = React.useState(false);
  const [isNetErr, setIsNetErr] = React.useState(false);

  const handleSubmit = (username: string, password: string) => {
    //console.log("handleSubmit:", username, password);
    internal_user = username;
    internal_password = password;
    const args = { user: username, password: password };

    dispatch(getValidatesThunk(args)); //Validate credentials
    //console.log("USERS:", validate);
  };

  const exitApplication = () => {
    _lp.handleExit();
  };

  useEffect(() => {
    dispatch(getSystemsThunk()); //Retrieve platform type e.g. dev, uat, live

    console.log("SYSTEM:", _lp.systemName);
    if (process.env.REACT_APP_VERSION !== undefined) {
      dispatch(getApplicationsThunk(_lp.systemName)); //Retrieve application version from database and build
    }

    dispatch(getRoleUsersThunk({ role: _lp.userRole })); //Get list of role users

    dispatch(getRoleSupervisorsThunk({ role: _lp.userSupervisorRole })); //Get list of supervisors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Have we eneter correct credentials?
  if (validate === "PASS" && role_supervisors !== undefined) {
    fail_description = "PASS";
    var sups = role_supervisors.filter(function (
      user: IRoleSupervisor,
      i: number
    ) {
      return user.UserName === internal_user;
    });

    //Callback function passed down from app_callback_ok in top-level
    _lp.handleConfirmOk({
      user: internal_user,
      password: internal_password,
      isSupervisor: sups.length > 0 ? true : false,
    });
  }

  //Have we entered incorrect credentials
  if (validate === "FAIL" && internal_user.length === 0) {
    fail_description = "No user selected";
  }

  if (validate === "FAIL" && internal_user.length !== 0) {
    fail_description = "User/Password is incorrect";
  }

  if (validate === "FAIL") {
    //Callback function passed down from app_callback_fail in top-level
    _lp.handleConfirmFail();
  }

  let wait_cursor = true;

  //Have all promises completed for dispatch calls? If so turn off cursor and proceeed
  if (
    applicationLoading === ELoadingStatus.loaded ||
    systemLoading === ELoadingStatus.loaded ||
    roleSupervisorLoading === ELoadingStatus.loaded ||
    roleUserLoading === ELoadingStatus.loaded
  ) {
    wait_cursor = false;
  } else {
    wait_cursor = true;
  }

  //test to see if network down
  if (
    appError.length > 0 &&
    sysError.length > 0 &&
    rolError.length > 0 &&
    rlsError.length > 0 &&
    valError.length > 0 &&
    !isNetErr
  ) {
    wait_cursor = false;
    setIsNetErr(true);
    console.log("No Network Connection:", appError);
  }

  //Are all condtions met to allow login screen to close?
  if (!isUsersLoaded && roleUserLoading === ELoadingStatus.loaded) {
    setIsUsersLoaded(true);
  }

  return (
    <div id="login-canvas">
      {!wait_cursor && appError.length > 0 ? (
        <StageNowDlg
          systemName={_lp.appName}
          currentVersion={curVer !== undefined ? curVer : "0.0.0"}
          newVersion={minVer !== undefined ? minVer : "0.0.0"}
        />
      ) : wait_cursor || !isUsersLoaded ? (
        <>
          {isNetErr ? <div>Network Error</div> : <div>Loading...</div>}
          {isNetErr ? (
            <div id="top-spinner">
              <img src={error_triangle} height="100px" id="spin" alt="spin" />
            </div>
          ) : (
            <div id="top-spinner">
              <img src={loading_gif} height="100px" id="spin" alt="spin" />
            </div>
          )}
          <Button
            className={classes.buttons}
            variant="contained"
            color="primary"
            onClick={exitApplication}
          >
            CANCEL
          </Button>
        </>
      ) : (
        <LoginView
          appLabel={_lp.appName}
          appName={_lp.appName}
          appIcon={_lp.appIcon}
          enableRoles={_lp.enableRoles}
          version={
            applications !== undefined && applications[0] !== undefined
              ? applications[0].current_version
              : ""
          }
          systemName={
            systems !== undefined && systems[0] !== undefined
              ? systems[0].system_name
              : ""
          }
          fp_user={"fp_user"} //! Tells TypeScript that even though something looks like it could be null, it can trust you that it's not
          users={
            role_users !== undefined && role_users![0] !== undefined
              ? role_users
              : []
          }
          handleSubmit={handleSubmit}
          handleExit={exitApplication}
          validate={fail_description}
        />
      )}
    </div>
  );
}

export default LoginContainer;
