import React, { useEffect } from "react";
import { ELoadingStatus } from "../redux/Enums";
import { useAppDispatch } from "../Framework/hooks";
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GridColDef } from '@mui/x-data-grid';
import MagnoGrid from "../Framework/MagnoGrid/MagnoGrid";

/* ICONS */

import "../css/App.css";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";

import {
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Typography,
} from "@material-ui/core";

import "../css/Panel.css";


import { 
  selectAttendeesResponseStatus,
  selectAttendeesStatus,
  selectAttendees,
  ResetLoadingStatus,
  getAttendeesThunk,

} from "../redux/JamHawSlice";

import {
    IAttendee,
    View,
} from '../redux/JamHawInterface';
import { Bool } from "reselect/es/types";

//Theme colours
const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        MuiAccordion:
        {
          root:
          {
            Color: "green",
          }
        }
      },
    },
    MuiList: {
      root: {
        width: "100%",
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          color: "yellow",
          borderRadius: "6px",
          "& .MuiListItemIcon-root": {
            color: "white"
          }
        },        
        //paddingBottom: "0px",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",        
        borderBottomColor: "#a0a0a0",

      },
    },    
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        boxShadow: "none",
      },
 
    },
    MuiAccordionSummary:
    {
      root: {
        "&:hover": {
          color: "yellow",
        },          
        width: "100%",
        padding: "0px",
      },
    },
    MuiAccordionDetails: {
      root: {
        "&:hover": {
          color: "yellow",
        },             
        marginLeft: "10px",
      },
    },
  },  
  palette: {
    primary: { light: "#416000", main: "#416000", dark: "#416000" },
    secondary: { light: "#6385BB", main: "#6385BB", dark: "#6385BB" },
  },
  
});

const useStyles = makeStyles((theme) => ({
  canvas: {
    height: "100%",
    width: "100%",
    userSelect: "none",
    //Why does this stop the canvas scroll?
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "white",
    // textAlign: "center",
  },
  inner_canvas: {
    height: "calc(100% - 60px)",
    width: "100%",
    userSelect: "none",
    //Why does this stop the canvas scroll?
    // borderStyle: "solid",
    // borderWidth: "1px",
    // borderColor: "cyan",
    // textAlign: "center",
    overflow: "hidden",
    //scrollbarWidth: "none",
    
  },
  item: {
    display: "inline-flex",
    width: "100%",
    // borderBottomStyle: "solid",
    // borderBottomWidth: "1px",
    // borderBottomColor: "silver",    
  },
}));


export const AttendeeList = () => {
  const dispatch  = useAppDispatch();
  const classes = useStyles();
  
  const attendees = useSelector(selectAttendees);
  const attendeesStatus = useSelector(selectAttendeesStatus);
  const attendeesResponseStatus = useSelector(selectAttendeesResponseStatus);

  /* HOOKS */
  const [open, setOpen] = React.useState(false);
  
  /**HOOKS */
  const [view, setView] = React.useState(View.INITIAL);


  useEffect(() => {
    dispatch(getAttendeesThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let iv={
    view: view
  }
    if(attendeesStatus === ELoadingStatus.loaded)
    {
        setTimeout(function(){
        dispatch(ResetLoadingStatus());
        }, 500);
    }

    if(attendeesResponseStatus === ELoadingStatus.loaded)
    {
        setTimeout(function(){
        dispatch(ResetLoadingStatus());
        dispatch(getAttendeesThunk());
        }, 500);
    }

    const attendeeColumn: GridColDef[] = [
        { field: 'email', headerName: 'Email', width: 266, type: "text"}, 
        { field: 'group', headerName: 'Group Name', width:330, type: "text"},
        { field: 'count', headerName: 'Count', width:50, type: "number"}
    ];

    let totals:number = 0;
    attendees.forEach((grp:IAttendee) => {
        totals = totals + grp.count;
    })

    return (
        <div id="main_canvas" className={classes.canvas}>

        <Box
            sx={{
            justifyContent:"center",
            alignItems:"center",          
            display: 'flex',
            padding: '5px',
            breakInside: 'auto',
            flexWrap: 'wrap',
            '& > :not(style)': {
                m: 3,
                width: 700,
                height: 500,
            },
            }}
        >
            <Paper elevation={4}
                style={{
                    padding: 10,
                    border: "1px solid black",
                    scrollbarWidth: "none",
                    overflow: 'auto'            
        
                }}>
                    
                    <Typography  
                        style={{
                            fontWeight: 800,
                        }}
                        variant="h6">
                        Party Attendees            
                    </Typography>   
                    <div className={classes.inner_canvas}>           
                        <MagnoGrid
                            objectId={"ATTENDEE_VIEW"}
                            rowHeight={25}
                            sx={{
                                height: "450px",
                            }}
        
                            columns={attendeeColumn} 
                            rows={attendees}
                        />  
                    </div>
                    <Typography  
                        style={{
                            fontWeight: 400,
                        }}
                        variant="h5">
                        Total Attendees {totals}            
                    </Typography>                  
            </Paper>      
        </Box>      
        </div>
    );
}

export default AttendeeList;

