

export function Gallery2021(){

  return {
  "images": [
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_014.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_023.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_023.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_024.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_024.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_025.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_025.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_026.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_026.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_032.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_033.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_033.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_034.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_034.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_035.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_035.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_036.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_036.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_037.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_037.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_038.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_038.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_039.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_039.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_040.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_040.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_041.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_041.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_042.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_042.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_043.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_043.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_044.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_044.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_045.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_045.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_046.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_046.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_047.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_047.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_048.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_048.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_049.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_049.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_050.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_050.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_051.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_051.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_052.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_052.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_053.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_053.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_054.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_054.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_055.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_055.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_056.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_056.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_057.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_057.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_058.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_058.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_059.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_059.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_060.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_060.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_061.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_061.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_062.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_062.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_063.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_063.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_064.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_064.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_065.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_065.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_066.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_066.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_067.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_067.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_068.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_068.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_069.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_069.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_070.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_070.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_071.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_071.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_072.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_072.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_073.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_073.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_074.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_074.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_075.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_075.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_076.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_076.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_077.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_077.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_078.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_078.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_079.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_079.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_080.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_080.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_081.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_081.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_082.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_082.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_083.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_083.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_084.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_084.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/Gallery2021/LRG_085.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/Gallery2021/SML_085.jpg"
        }
      ]
    }
}