import { configureStore } from "@reduxjs/toolkit";
import SessionSliceReducer from "./redux/SessionSlice";
import MediaSliceReducer from "./redux/MediaSlice";
//import mainSliceReducer from "./redux/MainSlice";
import LoginReducer from "./Framework/Login/LoginSlice";
import JamHawReducer from "./redux/JamHawSlice";

const store = configureStore({
  reducer: {
    login: LoginReducer,
    session: SessionSliceReducer,
    media: MediaSliceReducer,
    jamhaw: JamHawReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
