import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import '../css/App.css';
import {IsMobile} from "./MainLibrary";

export function Faq(props: any): JSX.Element {

  return (
      <div className="sub-canvas">
        <Box
        sx={{
            justifyContent:"center",
            alignItems:"center",          
            display: 'flex',
            padding: '5px',
            breakInside: 'auto',
            flexWrap: 'wrap',
            '& > :not(style)': {
            m: 3,
            width: 600,
            height: 500,
            },
        }}
        >
            <Paper elevation={4}
                style={{
                    padding: 10,
                    border: "1px solid black",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    width: 1200,
                    height: 700,
                }}
            >
                <b>About Us</b><br/><br/>

                <div><b>Q1)</b> Can I wear a costume?</div>
                    <p><i>Yes you can. Anything in the theme of pirates, mermaids, English sailing uniform, nautical, Hawaiian, Caribbean and Cuban</i></p>
                <div><b>Q2)</b> How many people can I bring?</div>
                    <p><i>We like to say a maximum of four people including yourself, but you must be able to vouch for the character and
                    behavior of everyone as you will be held responsible. And if they get out of hand, they will be asked &quot;POLITELY&quot; to leave.</i></p>
                <div><b>Q3)</b> Do you cater for vegetarians?</div>
                    <p><i>Yes, they'll be a few salad leafs that you can all suck on for the night. Seriously we have a lot of good none meat stuff as well. Everyone is catered for here.</i></p>
                <div><b>Q4)</b> Can I crash at your place?</div>
                    <p><i>If you're still at the party till 04:00 then you're welcome to bring a sleeping bag and crash out in "The Cave" and join the breakfast crowd.</i></p>
                <div><b>Q5)</b> Should I bring a bottle?</div>
                    <p><i>Their will be plenty to drink from the Carribbean Bar but the more there is, the more there is. We prefer you bring us some bubbly as we don't mind having a lot of that left over to consume over the winter months.</i></p>
                <div><b>Q6)</b> Are you having fireworks this year?</div>
                    <p><i>Maybe. We can then all walk donw to the nearby park and set them off..</i></p>
                <div><b>Q7)</b> How long does it take you to prepare this party?</div>
                    <p><i>We start preparing the garden a couple of weeks before July
                    and then catering the last few days before the party. The Caribbean bar &amp; club always has a thorough clean-up and gets fully stocked.</i></p>
                <div><b>Q8)</b> Do the neighbours moan about the noise?</div>
                    <p><i>Ask 'em when you see 'em they'll be here. or on holiday.</i></p>
                <div><b>Q9)</b> How long does it take you to clean-up and pack away?</div>
                    <p><i>One day (with a little help).</i></p>
                <div><b>Q10)</b> Can we bring our kids?</div>
                    <p><i>Alas no. It's strictly an adults only venue. Ages 16 and 17 are allowed under parental supervision..</i></p>
                <div><b>Q11)</b> What do you get out of all this?</div>
                    <p><i>We get to show our friends how much we love 'em all and how you can really throw a brilliant party. Plus when someone tells you that they've had the best Hallowe'en party ever and no other party can touch
                    yours then you know you've done something great.</i></p>
                <div><b>Q12)</b> Why don't you charge people entry?</div>
                    <p><i>We are not a public venue, we are not trying to make money, we don't do this because we have to, we do this because we love you all
                    and if for one night of the year us grown-ups can forget about all our troubles and enjoy that little bit of magic that we had when we were
                    kids then it's all been worth it. We don't live in wonder or magic anymore but we can still create it.</i></p>
                <div><b>Q13)</b> How long does it take you to clean up and get back to normal?</div>
                    <p><i>Usually one day (weather permitting).</i></p>
                <div><b>Q14)</b> What time should I arrive (and go)?</div>
                <p><i>This year we're starting at 16:30 -17:00 to give us time to get ready and meet you at the door.</i></p>
    
            </Paper>
        </Box>    
      </div>)
}

export default Faq;
