import { IFPAppbarProps } from "../Framework/FPAppBar/FPAppbar";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../Framework/hooks";
import { IMenuItem, menu_list} from './MainContainer';
import { UpdateView, selectSubMenu } from "../redux/SessionSlice";
import { ViewTypes } from "./views";

declare global {
  interface Window {
    device: any;
  }
}
export const GetDate = (dtx:string) =>  {
  if(dtx !== null)
  {
    let dt = dtx.replace(")/","").replace("/Date(","");
    //console.log("DATE:", dt);
    let d = new Date(Number(dt));
    return String(d.getDate()).padStart(2, '0')+"-"+String(d.getMonth()+1).padStart(2, '0')+"-"+d.getFullYear();
  }
  return "";
}

export const GetUnleashedDate = (dtx:string) =>  {
if(dtx !== null)
{
  let dt = dtx.replace(")/","").replace("/Date(","");
  //console.log("DATE:", dt);
  let d = new Date(Number(dt));
  //console.log("DATE2:", d.toISOString());
  return d.toISOString();
}
return "";
}

export const GetAWS_Date = (dtx:string) =>  {
  if(dtx !== null)
  {
    //console.log("DATE:", dtx);
    let d = (new Date(dtx.substring(0, 10)));
    return String(d.getDate()).padStart(2, '0')+"-"+String(d.getMonth()+1).padStart(2, '0')+"-"+d.getFullYear();
  }
  return "";
}
export const IsMobile = () => {
  let elem = document.getElementById("root");
  let rect = elem!.getBoundingClientRect();             
  if(rect.width < 645)
  {
      return true;
  }  
  return false;
}

const useStyles = makeStyles((theme) => ({
  show: {
    color: "black",
  },
  hide: {
    color: "silver",
  },
}));

export const GetMenutTitle = (key: string) => {

  let title:string = "UNKNOWN";
  menu_list.forEach((item:IMenuItem, i:number) => {

    if(key === item.key)
    {
       title = item.title;
    }

    if(item.subitems.length>0)
    {
      item.subitems.forEach((subitem:IMenuItem, ii:number) => {
        if(key === subitem.key)
        {
          title = subitem.title;
        }        
      })
    }
  })
 return title;
}

/**
 * Updates the menu items in the submenu navigation list by retrieving the view info from the SessionSlice
 * and adding it to the appbar object barProps.
 * @param barProps
 * @param exclude_view
 */
export function GetSubMenu(barProps: IFPAppbarProps, exclude_view: string) {
  //const trolleyData = useSelector(selectTrolleyData);
  //const sessionView = useSelector(selectView);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const state = useSelector((state: any) => state);
  const subMenu = useSelector(selectSubMenu);

  const navigationOnMenuItemClick = (event: any) => {
    //console.log("GetSubMenu", event.currentTarget.id);
    let view = event.currentTarget.id;

    if (view === "LOGOUT") {
      console.log("LOGOUT");
      localStorage.setItem("fp_pswd", "");
      window.location.reload();
      return;
    }

    if (view === ViewTypes.INFO_VIEW) {
      //Goto INFO_VIEW
      //console.log("ClearObjectSession", view);
      setTimeout(function () {
        dispatch(UpdateView(ViewTypes.DATE_PICKER_VIEW));
      }, 500);
    }
  };

  barProps.rightMenuItems = [];
  subMenu.forEach(function (item: any, i: number) {
    if (item.zone !== exclude_view) {
      if (item.enabled || state.systems.systems[0] === "DEV") {
        barProps.rightMenuItems!.push(
          <MenuItem
            key={i}
            id={item.zone}
            className={classes.show}
            onClick={navigationOnMenuItemClick}
          >
            {item.title}
          </MenuItem>
        );
        return;
      }

      barProps.rightMenuItems!.push(
        <MenuItem key={i} id={item.zone} className={classes.hide}>
          {item.title}
        </MenuItem>
      );
    }
  });
}

/**
 *
 * @returns Returns a current datetime in YYYY-MM-DDTHH:MM:SS.MMMZ format.
 */
export function GetTodaysDate() {
  let julianDate = "";
  let date = new Date();
  julianDate = date.getFullYear() + "-";
  julianDate =
    julianDate +
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) +
    "-";
  julianDate =
    julianDate + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
  julianDate = julianDate + "T00:00:00.000Z";
  return julianDate;
}
