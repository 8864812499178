import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import drinks_menu from '../images/drinks_list_2.png';
import gif_kramer from '../images/gif/kramer.gif';
import gif_woman1 from '../images/gif/woman1.gif';
import gif_woman2 from '../images/gif/woman2.gif';
import gif_woman3 from '../images/gif/woman3.gif';
import gif_rogen from '../images/gif/rogen.gif';
import gif_murray from '../images/gif/murray.gif';
import gif_belusci from '../images/gif/belusci.gif';
import gif_peg from '../images/gif/peg.gif';
import gif_monica from '../images/gif/monica.gif';
import {IsMobile} from "./MainLibrary";

const useStyles = makeStyles((theme) => ({
  canvas: {
    width: "calc(100% - 105px)",
    // height: "300px",
  },

}))

export function Drinks(props: any): JSX.Element {
  
  const dispatch  = useDispatch();

  const classes = useStyles();

  useEffect(() => {
  }, [dispatch]); 
  
  return (
    <div className="sub-canvas">
        <Box
          sx={{
            justifyContent:"center",
            alignItems:"center",          
            display: 'flex',
            padding: '5px',
            breakInside: 'auto',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 3,
              // width: 400,
              // height: 600,
            },
          }}
        >
          <Paper elevation={4}
            style={{
                padding: 10,
                border: "1px solid black",
                overflow: "auto",
                scrollbarWidth: "none",
                width: 500,
                height: 700,
              }}
           >
            <b>Drinks</b><br/><br/>
             What would a party be without booze?<br/><br/>
             As you will see when you get here the last thing
             anyone has to worry about is what to drink.
             We have a vast (and I mean vast) amounts of
             alcohol and fizzy drinks.<br/><br/>
             The Caribbean bar is where the actions at.<br/><br/>
             This bar is usually manned by two mixologists
             that can create any cocktail (within reason) for you.<br/><br/>
             We try and keep the range of cocktails to a set
             menu but that doesn't mean you can't go off-piste
             providing we have all the ingredients.<br/><br/>
             Previous parties have taught us what to expect and
             not to run out of the essentials like Rum, Vodka
             and Ice.<br/><br/> Ice being the most important part of it.
             The hot summer of 2018 was a challenge but we got
             through it with a little help from our friends
             and Tescos.<br/><br/>
             We serve Champagne, Cava and Presecco throughout 
             the day and night followed by our white Wine
             and a large selection of reds.<br/><br/>
             Because the drinks flow quite freely at our
             party we insist that you pace yourself and try
             not to get sick our cause trouble.<br/><br/>
             This is our home and not a hired venue so respect
             us and we'll always repect you. <br/><br/>
             Feel free to bring some extra bottles of bubbly
             as a token but you're not obligated to bring
             anything but yourself and a big smile, and of
             course your best singing voice.<br/>              
          </Paper>
            
          <Paper elevation={4}
              style={{
                  padding: 10,
                  border: "1px solid black",
                  scrollbarWidth: "none",
                  overflow: 'auto'            
      
                }}
             >
                <div>        
                  <div className="drinks_menu">
                    <img src={drinks_menu} alt="drinks_menu" width="400px"/>
                  </div>
                </div>        
          </Paper>
            
          <Paper elevation={4}
              style={{
                  padding: 10,
                  border: "1px solid black",
                  display: "inline-table",
                  width: 450,
                  height: 380,
                }}
             >                
                <div>        
        
                    <img src={gif_kramer} alt="gif_kramer"  width="150px" height="120px"/>
                    <img src={gif_woman1} alt="gif_woman1" width="150px" height="120px"/>
                    <img src={gif_woman2} alt="gif_woman2" width="150px" height="120px"/>
                    <img src={gif_woman3} alt="gif_woman3"  width="150px"/>
                    <img src={gif_rogen} alt="gif_rogen" width="150px" height="120px"/>
                    <img src={gif_murray} alt="gif_murray" width="150px" height="120px"/>
                    <img src={gif_belusci} alt="gif_belusci" width="150px" height="120px"/>
                    <img src={gif_peg} alt="gif_peg" width="150px" height="120px"/>
                    <img src={gif_monica} alt="gif_monica" width="150px" height="120px"/>
                </div>
            </Paper>
          </Box>
        </div>
  );
}
