import '../css/App.css';
import React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {IImage, IPicture} from './MainInterface';
import {
  Typography,
} from "@material-ui/core";
import { Gallery2022 } from './gallery/Gallery2022';
import { Gallery2021 } from './gallery/Gallery2021';
import { Gallery2019 } from './gallery/Gallery2019';
import { Gallery2018 } from './gallery/Gallery2018';
import { Gallery2017 } from './gallery/Gallery2017';
import { Gallery2016 } from './gallery/Gallery2016';
import { Gallery2015 } from './gallery/Gallery2015';
import { Gallery2014 } from './gallery/Gallery2014';
import { Gallery2013 } from './gallery/Gallery2013';
import PictureViewerX from './PictureViewerX';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: "5px",
    marginRight: "5px",
    padding: "2px",
  },
  canvas:{
    width: "100%",
    height: "100%",
  }
}))

const years:string[] = ["2022", "2021","2019","2018","2016","2015","2014","2013"];

var img:IImage[] = []; 


const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  marginRight: '4px',
  marginBottom: '4px',
  lineHeight: 1.5,
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(255,255,0,.5)',
  },
});

export function Picture(): JSX.Element {
  const classes = useStyles();
  const images:IPicture[] = []; 

  const [selectedYear, SetSelectedYear] = React.useState(years[0]);

  const handleUserChange = (event: any) => {
    //console.log(event.currentTarget.id.split("_")[1]);
    SetSelectedYear(event.currentTarget.id.split("_")[1]);
  };

  if(selectedYear)
  {
    switch(selectedYear){
      case '2022': img = Gallery2022().images; break;
      case '2021': img = Gallery2021().images; break;
      case '2019': img = Gallery2019().images; break;
      case '2018': img = Gallery2018().images; break;
      case '2017': img = Gallery2017().images; break;
      case '2016': img = Gallery2016().images; break;
      case '2015': img = Gallery2015().images; break;
      case '2014': img = Gallery2014().images; break;
      case '2013': img = Gallery2013().images; break;
    }
    //console.log(selectedYear, img);
    //SetYearGallery(year);
    //SetGallery(img.images);    
  }
  //console.log(years);
  let ip = {images : img};

  return (
      <div id="picture" className={classes.canvas}>
        <Box sx={{ flexGrow: 1 }}>

            {years.map((item, index) => (
            
              <BootstrapButton 
                variant="contained" 
                color="success"
                className={classes.btn} id={"item_"+item} key={"item_"+index} onClick={handleUserChange}>
                {item}
              </BootstrapButton>


            ))}

            <Paper elevation={4}
              style={{
                
                  padding: 0,
                  border: "1px solid black",
                  scrollbarWidth: "none",
                  overflow: 'auto'            
                }}>        
              <PictureViewerX {...ip} /> 
                <Typography  
                  style={{
                    fontWeight: 800,
                  }}
                  variant="h6">
                  Gallery {selectedYear}
                </Typography>                  
            </Paper>   

                     
        </Box>
      
      </div>)
}

export default Picture;
