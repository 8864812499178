import React, { useEffect } from "react";
import { ELoadingStatus } from "../redux/Enums";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import md5 from "md5";

import {
  ICredentials, 
  IAttendee,
} from '../redux/JamHawInterface';

import {
  selectValidate,
  // selectValidateStatus, 
  selectCredentials,
  UpdateShowMenu,
  UpdateShowSidebar,  
  selectAttendees,
  selectPasswordFail,
  selectEmailFail,
} from "../redux/JamHawSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    radius: "9px",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#67ca68", //theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  select: {
    width: "100%",
    color: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  systemLive: {
    fontSize: "1rem",
    textAlign: "center",
    border: "solid",
    display: "block",
    color: "red",
    backgroundColor: "#f59c9c",
  },  
  version: {
    marginTop: theme.spacing(4),
  },
  main: {
    position: "absolute",
    marginLeft: "calc(50% - 228px)",
    backgroundColor: "white",
    radius: "9px",
    zIndex: 9,
  },
  main_mobile: {
    position: "absolute",
    backgroundColor: "white",
    radius: "9px",
    zIndex: 9,
  }  
}));

export interface ILoginViewProps {
  appLabel: string;
  appName: string;
  systemName: string;
  version: string;
  fp_user: string;
  //users: IUser[];
  handleSubmit: (username: string, password: string, group: string) => void;
  handleExit: () => void;
}

export const Login = (props: ILoginViewProps): JSX.Element => {
  //////////////////////////////////////////////////////////////////////
  const classes = useStyles();
  const dispatch  = useDispatch();
  const inputRef = React.useRef();
  const [name, setName] = React.useState("");
  const [userError, setUserError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [groupNameError, SetGroupNameError] = React.useState("");
  const [groupName, SetGroupName] = React.useState("");
  const attendees = useSelector(selectAttendees);
  const credentials = useSelector(selectCredentials);
  const passwordFail = useSelector(selectPasswordFail);
  const emailFail = useSelector(selectEmailFail);
  const [mobile, SetMobile] = React.useState(false);
  //const validateStatus = useSelector(selectValidateStatus);
  const validate = useSelector(selectValidate);
  //////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let elem = document.getElementById("root");
    let rect = elem!.getBoundingClientRect();             
    if(rect.width < 645)
    {
        SetMobile(true);
    }  
    else
    {
        SetMobile(false);
    }
    window.addEventListener('resize', function() {
        let elem = document.getElementById("root");
        let rect = elem!.getBoundingClientRect();          
        if(rect.width < 645)
        {
            SetMobile(true);
        }  
        else
        {
            SetMobile(false);
        }
    }, true);

      dispatch(UpdateShowMenu(false));
      dispatch(UpdateShowSidebar(false));

      let a_group= localStorage.getItem("groupname");
      
      if(a_group !== null && a_group !== "" && a_group !== undefined)
      {
        //console.log(a_group);
        SetGroupName(a_group);
      }

  }, [dispatch]); 

  //////////////////////////////////////////////////////////////////////
  const handleUserChange = (event:any) => {
    setName(event.target.value as string);
    if (event.target.value !== "") {
      setUserError("");
    }
  };

  //////////////////////////////////////////////////////////////////////
  const handleGroupNameChange = (event:any) => {

    if (String(event.target.value).length === 0) 
    {
      SetGroupName("");
      SetGroupNameError("This field is mandantory");
    }
    else
    {
      SetGroupName(event.target.value as string);
      SetGroupNameError("");
    }
  };

  //////////////////////////////////////////////////////////////////////
  const handlePasswordChange = (event: any) => {
    setPassword(md5(event.target.value as string));
    if (event.target.value !== "") {
      setPasswordError("");
    }
  };

  //////////////////////////////////////////////////////////////////////
  //tell the parent container to close the application
  const handleExitClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    props.handleExit();
  };

  //////////////////////////////////////////////////////////////////////
  //perform validation on user name and password
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (name === undefined || name.length === 0) {
      setUserError("user name required");
      return;
    }

    if (password.length === 0) {
      setPasswordError("password is required");
      return;
    }
    //console.log("groupName", groupName);
    props.handleSubmit(name, password, groupName);
  };

  // console.log("passwordFail:", passwordFail);

  return (
    <Container className={(!mobile) ? classes.main : classes.main_mobile} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {props.appLabel}
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <FormControl className={classes.form}>
            <TextField
              name="user"
              margin="normal"
              variant="outlined"
              label="Email"
              error={validate.response === "BAD_EMAIL"}
              helperText={"Bad email format"}
              onChange={handleUserChange}
              autoFocus={true}
              fullWidth
              type="text"
            >
            </TextField>
            <br />
          </FormControl>
          <br />          
          <TextField
            id="group"
            variant="outlined"
            margin="normal"
            error={groupNameError.length > 0}
            helperText={groupNameError}
            fullWidth
            name="group"
            label="Group Name"
            type="text"
            onChange={handleGroupNameChange}
            value={groupName}
          />
          <br />          
          <TextField
            id="password"
            inputRef={inputRef}
            variant="outlined"
            margin="normal"
            error={validate.response === "PSWDFAIL"}
            helperText={"Incorrect password"}
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Log In
          </Button>
          <br />
          <br />
          <Button fullWidth variant="contained" onClick={handleExitClick}>
            EXIT
          </Button>
        </form>
        <FormLabel className={classes.version}>
          {
            (process.env.REACT_APP_VERSION !== undefined)
            ?<div>Version: {props.version}</div>
            :(null)
          }
        </FormLabel>
      </div>
    </Container>
  );
};

export default Login;
