

export function GalleryMAST(){



  return {
    "images": [
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_001.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_002.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_003.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_004.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_005.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_006.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_007.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_008.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_009.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_010.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_011.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_012.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_013.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_014.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_015.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_016.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_017.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_018.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_019.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_020.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_021.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_022.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_023.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_024.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_025.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_026.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_027.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_028.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_029.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_030.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_031.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_032.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_033.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_034.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_035.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_036.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_037.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_038.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_039.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_040.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_041.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_042.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_043.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_044.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_045.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_046.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_047.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_048.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_049.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_050.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_051.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_052.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_053.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_054.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_055.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryMAST/IMG_056.jpg"},
    ]
  }
}
