import '../css/App.css';
import carlton from '../images/carlton.gif';
import studio from '../images/studio.jpg';
import lounge from '../images/lounge.jpg';
import dolby from '../images/dolby.jpg';
import cave from '../images/cave.jpg';
import outdoor from '../images/outdoor.jpg';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
//import {IsMobile} from "./MainLibrary";

export function Music(props: any): JSX.Element {

  return (
    <div className="sub-canvas">
        <Box
          sx={{
            justifyContent:"center",
            alignItems:"center",          
            display: 'flex',
            padding: '5px',
            breakInside: 'auto',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 3,
              width: 400,
              height: 600,
            },
          }}
        >
            <Paper elevation={4}
                style={{
                    padding: 10,
                    border: "1px solid black",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    width: 700,
                    height: 430,
                }}
            >
                <b>Music</b><br/><br/>
                Being an avid musician and  film fanatic it
                would seem only fitting that I would be using
                a reasonable good sound system to deliver all the
                great music you will hear at this event.<br/><br/>
                Internally there's a 7.1 Dolby Surround DENON
                system in the Champagne lounge and a 7.1 Dolby
                Surround Panasonic system in The Cave.<br/><br/>
                Outside the house are four 40w marine-proof speakers
                that are connected to another Panasonic amplifier
                that is situated in my Audio Production Studio
                upstairs.<br/><br/> There are also two 60w speakers mounted
                outside the Caribbean Bar along with an external 
                Bass-Woofer.<br/><br/> 
                The music usually plays on (outside) till 2 AM
                but the Karaoke tends to take on a life all of
                it's own and continues further into the morning.<br/><br/>
                Our neighbours are well informed of the event and
                so far we've never had any issues with them.<br/><br/>
                Mainly because they're either with us or away.              
            </Paper>
            
            <Paper elevation={4}
                style={{
                    padding: 10,
                    width: 600,
                    height: 430,                    
                  }}
               >
                <img className="caption" height="400" src={studio} alt="studio"/><br/>
                <span>Audio Production Studio</span>
            </Paper>
            <Paper elevation={4}
                style={{
                    padding: 10,
                    width: 380,
                    height: 270, 
                  }}
               >
                    <img className="caption" height="250" src={lounge} alt="lounge"/><br/>
                    <span>Champagne Lounge</span>
            </Paper>
            <Paper elevation={4}
                style={{
                    padding: 10,
                    width: 250,
                    height: 320,   
                  }}
               >                    
                    <img className="caption"  height="300" src={dolby} alt="dolby"/><br/>
                    <span>Dolby 7.1 Surround &amp; THX</span>
            </Paper>
            <Paper elevation={4}
                style={{
                    padding: 10,
                    width: 225,
                    height: 320,   
                  }}
               >   
                    <img className="caption"  height="300" src={carlton} alt="carlton"/><br/>
                    <span>Carlton</span>
            </Paper>
            <Paper elevation={4}
                style={{
                    padding: 10,
                    width: 525,
                    height: 320,   
                  }}
               >   
                    <img className="caption"  height="300" src={cave} alt="cave"/><br/>
                    <span>The Cave</span>
            </Paper>
            <Paper elevation={4}
                style={{
                    padding: 10,
                    width: 250,
                    height: 320,   
                  }}
               >   
                    <img className="caption"  height="300" src={outdoor} alt="outdoor"/><br/>
                    <span>4x Outdoor Speaker System</span>
            </Paper>
        </Box>
    </div>)
}

export default Music;
