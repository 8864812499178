

export function GalleryKaraoke(){

  return {
  "images": [
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerykaraoke/LRG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerykaraoke/SML_014.jpg"
        }
      ]
    }
}