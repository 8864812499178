

export function GalleryRIGGING(){
  return {
    "images": [
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_001.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_002.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_003.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_004.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_005.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_006.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_007.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_008.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_009.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_010.jpg"},
        {"original": "https://jamhaw.com/images/gallery/GalleryRIGGING/IMG_011.jpg"}
    ]
  }
}
