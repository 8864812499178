import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { GalleryBar } from './gallery/GalleryBar';
import PictureViewer from './PictureViewer';
import {GetMenutTitle} from './MainLibrary';
import {
  Typography,
} from "@material-ui/core";
//import {IsMobile} from "./MainLibrary";

let ip = {images : GalleryBar().images};

export const Bar = () => {
  return (
    <div className="sub-canvas">
      <Box
        sx={{
          justifyContent:"center",
          alignItems:"center",          
          display: 'flex',
          padding: '5px',
          breakInside: 'auto',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 3,
            width: 600,
            height: 500,
          },
        }}
      >
        <Paper elevation={4}
          style={{
            
              padding: 10,
              border: "1px solid black",
              scrollbarWidth: "none",
              overflow: 'auto'            
            }}
        >
          <b>The Bar</b><br/><br/>
          How the bar became to be is a very long story that involves my friends in Dorset and a hotel in Kiev.<br/><br/> 
          I won't go into it here but it started as an in-joke with my friends and a nigthclub in Kiev called 
          the "Caribbean Club" where I was sitting alone in a huff until they all turned up laughing on how I 
          made my Humphrey Bogart-like exit from the hotel with my Fedora hat-flip and the last words:- "If anyone wants me.<br/><br/> 
          I'll be in the Caribbean Club".<br/><br/> 
          That phrase became the current get-me-out-of-here excuse for a long time.<br/><br/> 
          So in homage to that night (unknown to them) I created the bar for the last Halloween party in 2013.<br/><br/> 
          The bar was a success that night and over the next few years I started adding elements to it 
          like a sink, fridge, chest freezer etc. It also became apparent that we should out-source the bar to a 
          professional team who can really make things run a lot more smoothly. The "Mixology Group" in Brighton 
          was the company we eventually approached for this task. We have now used professional mixologists for 
          four years now and will continue doing this.<br/><br/> 
          The bar it'self will continue to be improved over the coming years.<br/><br/>
        </Paper>
        <Paper elevation={4}
            style={{
                padding: 10,
                border: "1px solid black",
                overflow: 'auto'            
    
              }}>
              <PictureViewer {...ip} /> 
              <br/>
              <Typography  
                style={{
                  fontWeight: 800,
                }}
                variant="h6">
                  {GetMenutTitle("BAR")}
              </Typography>              
          </Paper>      
      </Box>
    </div>
  );
}