import { createAsyncThunk, createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { ELoadingStatus } from "./Enums";
import { AxiosErrorHandler } from "../Framework/AxiosHelper/AxiosHelper";
import { RootState } from "../store";
import {
  IJamHawState, 
  ICredentials,
  IAttendee,
  IImage,
  IAttendeeResponse,
  IMerchandise,
  IMerchandiseResponse,
  } from './JamHawInterface';

const URL = process.env.REACT_APP_BASE_URL;

export interface IDialogManager {
  classx? : any;
  title: string;
  message: string; 
  button1: string; 
  button2?: string;
  button3?: string;
  exceptionType?: string;
  viewType?: string;
  data? : any;
}

///////////////////////////////////////////////////////////////////////
// BAY DATA PLAN
///////////////////////////////////////////////////////////////////////

export const getAttendeesThunk = createAsyncThunk(
  "jamhaw/attendees", async (_, thunkAPI) => {

  try {
        const response = await axios.get('https://stiletto.ddns.net/JamHawAPI/attendees', 
          {
            headers: { 
              'Access-Control-Allow-Origin': '*',
            },
            timeout: 10000,

          });
          return await response.data;
        } catch (error: any) {
          return thunkAPI.rejectWithValue(AxiosErrorHandler(error));
        }
  }
)

export const updateAttendeeCountThunk = createAsyncThunk(
  "jamhaw/update_attendees", async (args:IAttendee, thunkAPI) => {

  try {
        const response = await axios.post('https://stiletto.ddns.net/JamHawAPI/update_attendees', 
          JSON.stringify( args ),
          {
            headers: { 
              'Access-Control-Allow-Origin': '*',
            },
            timeout: 10000,

          });
          return await response.data; 
        } catch (error: any) {
          return thunkAPI.rejectWithValue(AxiosErrorHandler(error));
        }
  }
)

export const updateMerchOrderThunk = createAsyncThunk(
  "jamhaw/update_merch", async (args:IMerchandise, thunkAPI) => {

  try {
        const response = await axios.post('https://stiletto.ddns.net/JamHawAPI/update_merchandise', 
          JSON.stringify( args ),
          {
            headers: { 
              'Access-Control-Allow-Origin': '*',
            },
            timeout: 10000,

          });
          getAttendeesThunk();
          return await response.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(AxiosErrorHandler(error));
      }
  }
)

export const getMerchOrderThunk = createAsyncThunk(
  "jamhaw/getmerch", async (_, thunkAPI) => {

  try {
        const response = await axios.get('https://stiletto.ddns.net/JamHawAPI/merchandise', 
          {
            headers: { 
              'Access-Control-Allow-Origin': '*',
            },
            timeout: 10000,

          });
          return await response.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(AxiosErrorHandler(error));
      }
  }
)

export const validateThunk = createAsyncThunk(
  "jamhaw/validation", async (args:ICredentials, thunkAPI) => {

  try {
        const response = await axios.post('https://stiletto.ddns.net/JamHawAPI/validation', 
          JSON.stringify( args ),
          {
            headers: { 
              'Access-Control-Allow-Origin': '*',
            },
            timeout: 10000,

          });
          return await response.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(AxiosErrorHandler(error));
      }
  }
)

////////////////////////////////////////////////////
const initialState: IJamHawState = {
  
  attendees_loading: ELoadingStatus.idle,
  attendees_response_loading: ELoadingStatus.idle,  

  validate_loading: ELoadingStatus.idle,    
  merchandise_loading: ELoadingStatus.idle,  
  merchandise_response_loading: ELoadingStatus.idle,  

  error_validate_msg: "",
  
  error_attendees_msg: "",
  error_attendees_response_msg:"",

  error_merchandise_msg: "",
  error_merchandise_response_msg:"",
  
  isOK: false,
  isCANCEL: false,  
  showDialog: false,
  loggedIn: false,
  gdpr: false,
  credentials: {
    userName : "", 
    passWord: "",
    groupName: "",
  },
  galleryMode: false,
  showMenu: true,
  showSidebar: true,
  showFooter: true,
  view: "HOME_VIEW",
  dialogMessage: {  
    classx : "",
    title: "",
    message: "",
    button1: "",
    button2: "",
    exceptionType: "",
  } as IDialogManager,  
  attendees: [],
  gallery: [],
  merchandise: [],
  validate: {response:"", validation:""},
  attendees_response: {attendee: "", response:""},
  merchandise_response: {response: ""},
  passwordFail: false,
  emailFail: false,
}

///////////////////////////////////////////////////
export const JamHawSlice = createSlice({
  name: 'jamhaw',
  initialState,
  reducers: {

      ResetLoadingStatus: (state) =>{
          state.attendees_loading = ELoadingStatus.idle;
          state.validate_loading = ELoadingStatus.idle;   
          state.attendees_response_loading = ELoadingStatus.idle;
          state.merchandise_loading = ELoadingStatus.idle;
          state.merchandise_response_loading = ELoadingStatus.idle;
      },
      UpdateDialogMessage: (state, action: PayloadAction<IDialogManager>) => {
        state.dialogMessage = action.payload;
      },
      ClearDialogMessage: (state) => {
        state.dialogMessage.classx = "";
        state.dialogMessage.title = "";
        state.dialogMessage.message = "";
        state.dialogMessage.button1 = "";
        state.dialogMessage.button2 = "";
        state.dialogMessage.exceptionType = "";
      },   
      UpdateShowDialog: (state, action: PayloadAction<boolean>) => {
        //console.log("UpdateShowDialog", action.payload);
        state.showDialog = action.payload;
      },    
      UpdateIsOK: (state, action: PayloadAction<boolean>) => {
        state.isOK = action.payload;
      },      
      UpdateIsCANCEL: (state, action: PayloadAction<boolean>) => {
        state.isCANCEL = action.payload;
      },      
      UpdateView: (state, action: PayloadAction<string>) => {
        state.view = "BLANK";
        state.view = action.payload;
      },      
      UpdateLoggedIn: (state, action: PayloadAction<boolean>) => {
        state.loggedIn = action.payload;
      },     
      UpdateGDPR: (state, action: PayloadAction<boolean>) => {
        state.gdpr = action.payload;
      },         
      UpdateGalleryMode: (state, action: PayloadAction<boolean>) => {
        state.galleryMode = action.payload;
      },      
      UpdateShowMenu: (state, action: PayloadAction<boolean>) => {
        state.showMenu  = action.payload;
      },  
      UpdateShowSidebar: (state, action: PayloadAction<boolean>) => {
        state.showSidebar  = action.payload;
      },  
      UpdateShowFooter: (state, action: PayloadAction<boolean>) => {
        state.showFooter  = action.payload;
      },  
      UpdateCredentials: (state, action: PayloadAction<ICredentials>) => {
        state.credentials = action.payload;
      }, 
      UpdateAttendees: (state, action: PayloadAction<IAttendee[]>) => {
        state.attendees = action.payload;
      }, 
      UpdateGallery: (state, action: PayloadAction<IImage[]>) => {
        state.gallery = action.payload;
      },       
      UpdatePasswordFail: (state, action: PayloadAction<boolean>) => {
        state.passwordFail = action.payload;
      },     
      UpdateEmailFail: (state, action: PayloadAction<boolean>) => {
        state.emailFail = action.payload;
      },           
  
  },
  extraReducers: (builder) => {

      /////////////////////////////////////////////////////////////////////////////////////
      /** ATTENDEES **/
      builder.addCase(getAttendeesThunk.pending, (state) => {
        state.attendees = [];
        state.attendees_loading = ELoadingStatus.loading;
      });

      builder.addCase(getAttendeesThunk.fulfilled, (state, { payload }) => {
        //console.log(">>>",payload);
        state.attendees = payload.attendees;
        state.attendees_loading = ELoadingStatus.loaded;
      });
      
      builder.addCase(getAttendeesThunk.rejected,(state, action) => {
        state.attendees_loading = ELoadingStatus.error;
      });      

      /////////////////////////////////////////////////////////////////////////////////////
      /** ATTENDEES UPDATE **/
      builder.addCase(updateAttendeeCountThunk.pending, (state) => {
        state.attendees_response_loading = ELoadingStatus.loading;
      });

      builder.addCase(updateAttendeeCountThunk.fulfilled, (state, { payload }) => {
        state.attendees_response = payload;
        state.attendees_response_loading = ELoadingStatus.loaded;

      });
      
      builder.addCase(updateAttendeeCountThunk.rejected,(state, action) => {
        state.attendees_loading = ELoadingStatus.error;
      }); 

      /////////////////////////////////////////////////////////////////////////////////////
      /** VALIDATE **/
      builder.addCase(validateThunk.pending, (state) => {
        state.validate_loading = ELoadingStatus.loading;
      });

      builder.addCase(validateThunk.fulfilled, (state, { payload }) => {
        state.validate = payload;
        state.validate_loading = ELoadingStatus.loaded;
      });
      
      builder.addCase(validateThunk.rejected,(state, action) => {
        state.validate_loading = ELoadingStatus.error;
        console.log(action.error);
      });        

      /////////////////////////////////////////////////////////////////////////////////////
      /** GET MERCHANDISE **/
      builder.addCase(getMerchOrderThunk.pending, (state) => {
        state.merchandise_loading = ELoadingStatus.loading;
      });

      builder.addCase(getMerchOrderThunk.fulfilled, (state, { payload }) => {
        state.merchandise = payload.merchandise;
        state.merchandise_loading = ELoadingStatus.loaded;
      });
      
      builder.addCase(getMerchOrderThunk.rejected,(state, action) => {
        state.merchandise_loading = ELoadingStatus.error;
      });  

      /////////////////////////////////////////////////////////////////////////////////////
      /** MERCHANDISE UPDATE **/
      builder.addCase(updateMerchOrderThunk.pending, (state) => {
        state.merchandise_response_loading = ELoadingStatus.loading;
      });

      builder.addCase(updateMerchOrderThunk.fulfilled, (state, { payload }) => {
        state.merchandise_response = payload;
        state.merchandise_response_loading = ELoadingStatus.loaded;
      });
      
      builder.addCase(updateMerchOrderThunk.rejected,(state, action) => {
        state.merchandise_response_loading = ELoadingStatus.error;
      });       
    }
  },
)

export const {
  UpdateEmailFail,
  UpdatePasswordFail,
  ResetLoadingStatus,
  UpdateGallery,
  UpdateAttendees,
  UpdateCredentials,
  UpdateShowFooter,
  UpdateShowMenu,
  UpdateShowSidebar,
  UpdateGalleryMode,
  UpdateView,
  UpdateLoggedIn,
  UpdateGDPR,
  UpdateDialogMessage,
  ClearDialogMessage,
  UpdateShowDialog,
  UpdateIsOK,
  UpdateIsCANCEL,
} = JamHawSlice.actions;

//const selectSelf = (state: any) => state

export const selectIsOK           = (rootState: RootState) => { return rootState.jamhaw.isOK; };
export const selectIsCANCEL       = (rootState: RootState) => { return rootState.jamhaw.isCANCEL; };
export const selectDialogMessage  = (rootState: RootState) => { return rootState.jamhaw.dialogMessage; };
export const selectShowDialog     = (rootState: RootState) => { return rootState.jamhaw.showDialog; };
export const selectView           = (rootState: RootState) => { return rootState.jamhaw.view; };
export const selectLoggedIn       = (rootState: RootState) => { return rootState.jamhaw.loggedIn; };
export const selectGalleryMode    = (rootState: RootState) => { return rootState.jamhaw.galleryMode; };
export const selectShowMenu       = (rootState: RootState) => { return rootState.jamhaw.showMenu; };
export const selectShowSidebar    = (rootState: RootState) => { return rootState.jamhaw.showSidebar; };
export const selectShowFooter     = (rootState: RootState) => { return rootState.jamhaw.showFooter; };
export const selectCredentials    = (rootState: RootState) => { return rootState.jamhaw.credentials ; };
export const selectPasswordFail   = (rootState: RootState) => { return rootState.jamhaw.passwordFail; };
export const selectEmailFail      = (rootState: RootState) => { return rootState.jamhaw.emailFail; };
export const selectGDPR           = (rootState: RootState) => { return rootState.jamhaw.gdpr; };

export const selectGallery              = (rootState: RootState) => { return rootState.jamhaw.gallery ; };
export const selectValidate             = (rootState: RootState) => { return rootState.jamhaw.validate; };
export const selectAttendees            = (rootState: RootState) => { return rootState.jamhaw.attendees ; };
export const selectAttendeesResponse    = (rootState: RootState) => { return rootState.jamhaw.attendees_response ; };
export const selectMerchandise          = (rootState: RootState) => { return rootState.jamhaw.merchandise ; };
export const selectMerchandiseResponse  = (rootState: RootState) => { return rootState.jamhaw.merchandise_response ; };

/* STATUS */
export const selectAttendeesStatus            = (rootState: RootState) => { return rootState.jamhaw.attendees_loading; };
export const selectValidateStatus             = (rootState: RootState) => { return rootState.jamhaw.validate_loading; };
export const selectAttendeesResponseStatus    = (rootState: RootState) => { return rootState.jamhaw.attendees_response_loading; };
export const selectMerchandiseStatus          = (rootState: RootState) => { return rootState.jamhaw.merchandise_loading; };
export const selectMerchandiseResponseStatus  = (rootState: RootState) => { return rootState.jamhaw.merchandise_response_loading; };

/* ERRORS */
// export const selectValidateMsg            = createDraftSafeSelector(  selectSelf,  (state) => state.jamhaw.error_validate_msg);
// export const selectAttendeesMsg           = createDraftSafeSelector(  selectSelf,  (state) => state.jamhaw.error_attendees_msg);
// export const selectAttendeesResponseMsg   = createDraftSafeSelector(  selectSelf,  (state) => state.jamhaw.error_attendees_response_msg);
// export const selectMerchandiseMsg         = createDraftSafeSelector(  selectSelf,  (state) => state.jamhaw.error_Merchandise_msg);
// export const selectMerchandiseResponseMsg = createDraftSafeSelector(  selectSelf,  (state) => state.jamhaw.error_Merchandise_msg);





export default JamHawSlice.reducer