
export function GalleryBar(){

  return {
      "images": [
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_001.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_001.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_002.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_002.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_003.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_003.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_004.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_004.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_005.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_005.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_006.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_006.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_007.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_007.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_008.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_008.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_009.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_009.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_010.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_010.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_011.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_011.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_012.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_012.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_013.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_013.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_014.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_014.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_015.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_015.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_016.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_016.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_017.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_017.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_018.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_018.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_019.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_019.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_020.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_020.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_021.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_021.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_022.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_022.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_027.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_027.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_028.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_028.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_029.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_029.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_030.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_030.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_031.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_031.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_032.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_032.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_034.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_034.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_035.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_035.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_036.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_036.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_037.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_037.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_038.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_038.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_039.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_039.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_040.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_040.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_041.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_041.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_042.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_042.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_043.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_043.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_044.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_044.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_045.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_045.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_046.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_046.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_048.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_048.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_049.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_049.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_050.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_050.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_051.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_051.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_052.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_052.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_053.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_053.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_054.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_054.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_055.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_055.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_056.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_056.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_057.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_057.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_058.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_058.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_059.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_059.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_060.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_060.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_061.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_061.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_062.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_062.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_063.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_063.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_064.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_064.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_065.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_065.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_066.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_066.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_067.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_067.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_068.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_068.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_069.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_069.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_070.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_070.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_071.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_071.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_072.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_072.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_073.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_073.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_074.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_074.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_075.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_075.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_076.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_076.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_077.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_077.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_078.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_078.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_079.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_079.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_082.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_082.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_084.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_084.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_088.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_088.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_089.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_089.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_090.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_090.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_091.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_091.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_092.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_092.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_093.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_093.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_094.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_094.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_095.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_095.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_096.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_096.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_097.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_097.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_099.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_099.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_100.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_100.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_101.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_101.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_102.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_102.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_103.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_103.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_104.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_104.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_105.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_105.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_106.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_106.jpg"
        },
        {
          "original": "https://jamhaw.com/images/gallery/gallerybar/IMG_107.jpg",
          "thumbnail": "https://jamhaw.com/images/gallery/gallerybar/SML_107.jpg"
        }
      ]
    }

}