import React from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";

export interface iHideOnScrollProps {
  children: JSX.Element; //default react prop.
  isEnabled: boolean; //If true the child element will hide when you scroll.
  direction: "down" | "up"; //up we will animate to the top of thr screen when we hide.
}

/**
 * Wrap this around an area of JSX to have it hide when you scroll.
 * Most commonly used with the FPAppbar to hide when we scroll which is handy on mobile
 */
const HideOnScroll = (props: iHideOnScrollProps): JSX.Element => (
  <Slide
    appear={props.isEnabled}
    direction={props.direction}
    in={!useScrollTrigger() || !props.isEnabled}
  >
    {props.children}
  </Slide>
);

export default HideOnScroll;
