import React from "react";
import { useAppDispatch } from "../../Framework/hooks";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { IRoleUser } from "./LoginInterface";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#67ca68", //theme.palette.secondary.main,
  },
  green: {
    color: "green",
  },
  red: {
    color: "red",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  select: {
    width: "100%",
    color: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  systemDev: {
    fontSize: "1rem",
    textAlign: "center",
    border: "solid",
    display: "block",
    color: "#607d8b",
    backgroundColor: "#bed4e7",
  },
  systemLive: {
    fontSize: "1rem",
    textAlign: "center",
    border: "solid",
    display: "block",
    color: "red",
    backgroundColor: "#f59c9c",
  },
  version: {
    marginTop: theme.spacing(4),
  },
}));

interface ILoginViewProps {
  validate: string;
  appLabel: string;
  appName: string;
  appIcon?: JSX.Element;
  systemName: string;
  version: string;
  fp_user: string;
  users: IRoleUser[];
  enableRoles: boolean | undefined;
  handleSubmit: (username: string, password: string) => void;
  handleExit: () => void;
}

const LoginView = (props: ILoginViewProps): JSX.Element => {
  const classes = useStyles();
  const inputRef = React.useRef();
  const [name, setName] = React.useState(props.fp_user || "");
  const [userError, setUserError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const dispatch = useAppDispatch();

  const handleUserChange = (e: React.MouseEvent<HTMLLIElement>) => {
    //console.log(e.currentTarget.textContent);
    setName(String(e.currentTarget.textContent));
    setUserError("");
    if (document.getElementById("username-helper-text") !== null) {
      document.getElementById("username-helper-text")!.innerHTML = "";
    }
  };

  //let user = localStorage.getItem("fp_user");

  React.useEffect(() => {
    //console.log("React.useEffect");

    let user = localStorage.getItem("fp_user");

    if (user !== undefined) {
      if (user !== null) {
        if (user.length) {
          setName(user);
        }
      }
    }
  }, [dispatch]);

  //Get last user

  //clear the validation error if the password field has a value otherwise display an error.
  const handlePasswordChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPassword(event.target.value as string);
    if (event.target.value !== "") {
      setPasswordError("");
    }
  };

  const refreshRoleUsers = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.location.reload();
  };

  //tell the parent container to close the application
  const handleExitClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    props.handleExit();
  };

  //perform validation on user name and password
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (name === undefined || name.length === 0) {
      setUserError("user name required");
      return;
    }

    if (password.length === 0) {
      setPasswordError("password is required");
      return;
    }
    localStorage.setItem("fp_user", name);
    props.handleSubmit(name, password);
  };

  if (props.validate === "PASS") {
    if (document.getElementById("username-helper-text")! !== null) {
      document.getElementById("username-helper-text")!.style.visibility =
        "HIDDEN";
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} onClick={refreshRoleUsers}>
          {props.appIcon !== undefined ? props.appIcon : <LockOutlinedIcon />}
        </Avatar>
        <Typography id="app-label" component="h1" variant="h5">
          {props.appLabel}
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <FormControl className={classes.form}>
            <TextField
              id="username"
              label="User Name"
              select
              error={userError !== ""}
              helperText={props.validate === "PASS" ? "PASS" : props.validate}
              className={classes.green}
              //value={username.length !== 0 ? username : name}
              value={name}
            >
              {props.users.map((item, index) => (
                <MenuItem
                  id={item.UserName}
                  value={item.UserName}
                  key={item.UserId}
                  onClick={(e) => handleUserChange(e)}
                >
                  {item.UserName}
                </MenuItem>
              ))}
            </TextField>
            {/* </Select> */}
            <br />
          </FormControl>

          <TextField
            id="password"
            inputRef={inputRef}
            variant="outlined"
            margin="normal"
            error={passwordError.length > 0}
            helperText={passwordError}
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            autoFocus={true}
            onChange={handlePasswordChange}
          />
          <br />
          <br />
          <Button
            id="submit-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={
              props.systemName === "LIVE"
                ? classes.systemLive
                : classes.systemDev
            }
          >
            Log In - {props.systemName}
          </Button>
          <br />
          <br />
          <Button
            id="exit-button"
            fullWidth
            variant="contained"
            onClick={handleExitClick}
          >
            EXIT
          </Button>
        </form>
        <FormLabel className={classes.version}>
          {process.env.REACT_APP_VERSION !== undefined ? (
            <div id="version-text">
              Installed Version: {process.env.REACT_APP_VERSION}
            </div>
          ) : null}
          {props.version !== undefined ? (
            <div id="version-text">Latest Version: {props.version}</div>
          ) : null}
        </FormLabel>
      </div>
    </Container>
  );
};

export default LoginView;
