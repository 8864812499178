import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import {
  UpdateView,
  selectView, 
  selectCredentials,
  selectAttendees,
  UpdateShowMenu,
  UpdateShowSidebar,  
  getAttendeesThunk,
} from "../redux/JamHawSlice";

import {
  ICredentials, 
  IAttendee,
  IRsvpProps,
} from '../redux/JamHawInterface';

const useStyles = makeStyles((theme) => ({
  paper: {
    radius: "9px",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#67ca68", //theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  select: {
    width: "100%",
    color: "black",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  systemLive: {
    fontSize: "1rem",
    textAlign: "center",
    border: "solid",
    display: "block",
    color: "red",
    backgroundColor: "#f59c9c",
  },  
  version: {
    marginTop: theme.spacing(4),
  },
  main: {
    position: "absolute",
    marginLeft: "calc(50% - 228px)",
    backgroundColor: "white",
    radius: "9px",
    zIndex: 9,
  },
  main_mobile: {
    position: "absolute",
    backgroundColor: "white",
    radius: "9px",
    zIndex: 9,
  }  
}));

export const RSVP = (props: IRsvpProps): JSX.Element => {
  const classes = useStyles();
  const dispatch  = useDispatch();
  const inputRef = React.useRef();
  const attendees = useSelector(selectAttendees);
  const credentials = useSelector(selectCredentials);
  
   /** HOOKS */
  const [total, setTotal] = React.useState(0);
  const [totalError, settotalError] = React.useState("");
  const [good2Go, setGood2Go] = React.useState(false);
  
  
  useEffect(() => {
      dispatch(UpdateShowMenu(false));
      dispatch(UpdateShowSidebar(false));

      let att = attendees.filter(function(attd:IAttendee){
        return attd.email === credentials.userName
      });

      if(att.length === 1)
      {
        //console.log("ATT:", att[0].count);
        setTotal(att[0].count);
        setGood2Go(true);
      }

  }, []); 

  const handleCountChange = (event:any) => {
    //console.log(Number(event.target.value));
    if(Number(event.target.value) >= 0 )
    {
      if(Number(event.target.value) <= 5 )
      {
        settotalError("");
        if(event.target.value.length === 0)
        {
          setTotal(0);
        }
        else
        {
          setTotal(event.target.value as number);
        }
        setGood2Go(true);
        
      }
      else
      {
        setGood2Go(false);
        settotalError("Maximum of five people only");
      }
    }
    else
    {
      setGood2Go(false);
    }
  };


  //tell the parent container to close the application
  const handleExitClick = (event: React.SyntheticEvent) => {
    //event.preventDefault();
    props.handleExit();
  };

  //perform validation on user name and password
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(good2Go)
    {
      props.handleSubmit(total);
    }
  };
  
  return (
    <Container  className={classes.main} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          
        </Avatar>
        <Typography component="h1" variant="h5">
         {credentials.groupName}
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
    
          <TextField
            id="total"
            inputRef={inputRef}
            variant="outlined"
            margin="normal"
            error={totalError.length > 0}
            helperText={totalError}
            fullWidth
            name="total"
            label={ (total===0) ? "Number of attendees" : ""}
            type="number"
            onChange={handleCountChange}
            value={total}
            autoFocus
          />
          <br />
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            // className={
            //   props.systemName === "LIVE"
            //     ? classes.systemLive
            //     : classes.systemDev
            // }
          >
            ACCEPT
          </Button>
          <br />
          <br />
          <Button fullWidth variant="contained" onClick={handleExitClick}>
            EXIT
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default RSVP;
