import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import '../css/App.css';
import {IVideoInfo} from "./MainInterface";
import { makeStyles } from "@material-ui/core/styles";
import {IsMobile} from "./MainLibrary";

const useStyles = makeStyles((theme) => ({
    caption: {
        marginLeft: "5px",
      },
      video: {
        backgroundColor: "white",
        display: "inline-block",
        marginRight: "10px",
        marginBottom: "10px",
        borderStyle: "solid",
        borderWidth: "2px",
        borderRadius: "9px",
        borderColor: "white",
      },      
}))

export function VideoPanel(props: IVideoInfo): JSX.Element {
    const classes = useStyles();
    return(
        <div className={classes.video}>
            <video width="280" height="200" controls>
                 <source src={props.src} type="video/mp4"/>
             </video>
             <div className={classes.caption}><span>{props.caption}</span></div>
        </div>
    )
}

export function Video(props: any): JSX.Element {
    //const classes = useStyles();

    return (
        <div className="sub-canvas">
            <Box
                sx={{
                    justifyContent:"center",
                    alignItems:"center",          
                    display: 'flex',
                    padding: '5px',
                    breakInside: 'auto',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                    m: 3,
                    width: 600,
                    height: 500,
                    },
                }}
                >
                <Paper elevation={4}
                    style={{
                        padding: 10,
                        border: "1px solid black",
                        overflow: "auto",
                        
                        width: 800,
                        height: "100%",
                    }}
                >
                    <b>Video Library</b><br/>
                    <VideoPanel src={"videos/Tenpole.mp4"} caption={"Tenpole Tudor"}/>
                    <VideoPanel src={"videos/Annies_Song.mp4"} caption={"Annie's Song"}/>
                    <VideoPanel src={"videos/Happy_Couple.mp4"} caption={"Happy Couple"}/>
                    <VideoPanel src={"videos/In_The_Navy.mp4"} caption={"In The Navy"}/>
                    <VideoPanel src={"videos/Proclaimers.mp4"} caption={"Proclaimers"}/>
                    <VideoPanel src={"videos/Surfing_Pirate.mp4"} caption={"Pirate Hawaiian Dance"}/>
                    <VideoPanel src={"videos/dalek_parrot_dance.mp4"} caption={"Dalek Parrot Dance"}/>
                    <VideoPanel src={"videos/food_check.mp4"} caption={"Food Check Main"}/>
                    <VideoPanel src={"videos/food_check_dessert.mp4"} caption={"Food Check dessert"}/>
                    <VideoPanel src={"videos/FoodTime.mp4"} caption={"Food Time"}/>
                    <VideoPanel src={"videos/CheeseTalk.mp4"} caption={"Cheese Talk"}/>
                    <VideoPanel src={"videos/EarlyMorningClosingTime.mp4"} caption={"Closing Time"}/>
                    <VideoPanel src={"videos/JustBeforeArrivals.mp4"} caption={"Before Arrivals"}/>
                </Paper>
            </Box>    
        </div>)
}

export default Video;
